<template>
<link href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/fontawesome.min.css' rel='stylesheet'/>
<div class="container-fluid" style="padding: 3vw">
  <div class="card-container row">
    <div class="card sport col">
      <div class="card__icon"><font-awesome-icon icon="female" /></div>
      <h2 class="card__title">Algoritmi Donna</h2>
      <p class="card__apply"><a class="link" @click="select('sport')" >Seleziona <font-awesome-icon icon="arrow-right" /></a></p>
    </div>
    <div class="card anziani col">
      <div class="card__icon"><font-awesome-icon icon="male" /></div>
      <h2 class="card__title">Algoritmi Assistenza anziani</h2>
      <p class="card__apply"><a class="link" @click="select('anziani')">Seleziona <font-awesome-icon icon="arrow-right" /></a></p>
    </div>
    <div class="card salute col">
      <div class="card__icon"><font-awesome-icon icon="ambulance" /></div>
      <h2 class="card__title">Algoritmi Salute</h2>
      <p class="card__apply"><a class="link" @click="select('salute')">Seleziona <font-awesome-icon icon="arrow-right" /></a></p>
    </div>
  </div><div class="card-container row">
    <div class="card lavoro col">
      <div class="card__icon"><font-awesome-icon icon="hard-hat" /></div>
      <h2 class="card__title">Sicurezza Lavoro</h2>
      <p class="card__apply"><a class="link" @click="select('lavoro')">Seleziona <font-awesome-icon icon="arrow-right" /></a></p>
    </div>
    <div class="card trackcat col">
      <div class="card__icon"><font-awesome-icon icon="car" /></div>
      <h2 class="card__title">Tracciabilità</h2>
      <p class="card__apply"><a class="link" @click="select('trackcat')">Seleziona <font-awesome-icon icon="arrow-right" /></a></p>
    </div>
    <div class="card sicurezza col">
      <div class="card__icon"><font-awesome-icon icon="user-shield" /></div>
      <h2 class="card__title">Sicurezza Pubblica</h2>
      <p class="card__apply"><a class="link" @click="select('sicurezza')">Seleziona <font-awesome-icon icon="arrow-right" /></a></p>
    </div>
  </div>
</div>
</template>

<script>
export default {
    name: 'AlgoSelection',
    emits: ["changeCategory"],
    methods: {
      select(cat){
        this.$emit('changeCategory',cat)
      }
    }
}
</script>

<style>
* {
  margin: 0;
}

i.fa,
i.fas {
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
}

.container-fluid{
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.link,
.card__exit,
.card__icon {
  position: relative;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
  padding: 2vw;
  margin: 2vw;
}

.link::after {
  position: absolute;
  top: 25px;
  left: 0;
  content: "";
  width: 0%;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.6);
  transition: all 0.5s;
}

.link:hover{
    color: rgba(255, 255, 255, 0.9);
}

.link:hover::after {
  width: 100%;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
}

.card {
  margin: 10px;
  padding: 20px;
  width: 300px;
  min-height: 80px;
  grid-template-rows: 10px 30px 50px 10px;
  border-radius: 10px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.5s;
}

.card:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
  transform: scale(1.01);
}

.card__icon {
  grid-row: 2/3;
  font-size: 30px;
}

.card__title {
  grid-row: 3/4;
  font-weight: 400;
  color: #ffffff;
}

.card__apply {
  grid-row: 4/5;
  align-self: center;
}

.sport {
  background: radial-gradient(#ffb6b6, #983232);
}

.anziani, .salute, .trackcat, .lavoro, .sicurezza {
  background: radial-gradient(#999999, #5a5a5a);
}

/*
.salute {
  background: radial-gradient(#ccf5b6, #88a976);
}

.trackcat {
  background: radial-gradient(#b6b5b5, #6b6464);
}

.lavoro {
  background: radial-gradient(#c8b6ff, #9282c3);
}

.sicurezza {
  background: radial-gradient(#ffecb6 , #cba026 );
}
*/
</style>