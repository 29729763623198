<template>
<div class="row" style="overflow-x:hidden; margin-top: 2vh; overflow-y: scroll; height: 80vh;">
    <Param />
</div>
</template>

<script>
import Param from '../components/Param.vue'
export default {
    name: 'Params',
    components: {
        Param
    }
}
</script>
