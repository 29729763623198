<template>
<div class="container scroll1">
    <div class="row">
        <div class="col">
            <DateTimePicker :singleDate="true" :startDate="datetime" :timeFormat="'HH:mm'" @update:date="aggiorna" /> 
        </div>
        <div class="col">
            <button class="btn btn-primary" @click="carica">Disegna grafici</button>
        </div>
    </div>
    <div class="row rowspace">
        <h2>Accelerometro</h2>
        <div ref="chart1div" class="chart"></div>
    </div>
    <div class="row rowspace">
        <h2>Giroscopio</h2>
        <div ref="chart2div" class="chart"></div>
    </div>
    <div style="height: 5vh"></div>
</div>
</template>

<script>
import * as API from '../main.js';
import DateTimePicker from '../components/DateTimePicker.vue';
import {
    mapState
} from 'vuex';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
am4core.options.autoDispose = true;

export default {
    name: 'Racing',
    components: {
        DateTimePicker
    },
    data() {
        return {
            datetime: new Date(new Date().getTime() - (60 * 60 * 1000)),
            chart1div: {},
            chart2div: {}
        }
    },
    methods: {
        carica() {
            API.getMems(this.datetime.getTime()/1000)
            //this.updateChartData()
        },
        aggiorna(data){
            this.datetime = data.startDate
        },
        createSeries(field, name, chart) {
            let series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = field;
            series.dataFields.dateX = "date";
            series.name = name;
            series.tooltipText = "{dateX.formatDate('HH:mm:ss')}: {name} [b]{valueY}[/]";
            series.strokeWidth = 2;
            let lineseries = chart.series.push(new am4charts.LineSeries());
            lineseries.dataFields.valueY = field;
            lineseries.dataFields.dateX = "date";
            lineseries.strokeWidth = 2;
            if (field == "x")
                series.stroke = am4core.color("#ff0000");
            if (field == "y")
                series.stroke = am4core.color("#ffff00");
            if (field == "z")
                series.stroke = am4core.color("#ff00ff");
            return series
        },
        updateChartData() {
            console.log("UPDATING DATA")
            let data = []
            let data2 = []
            for (let t of this.mems[0]) {
                if (t.ts) {
                    let tmp = {
                        x: t.xa,
                        y: t.ya,
                        z: t.za/10,
                        date: new Date(t.ts*1000)
                    }
                    data.push(tmp)
                }
            }
            for (let t of this.mems[1]) {
                if (t.ts) {
                    let tmp = {
                        x: t.xa,
                        y: t.ya,
                        z: t.za/10,
                        date: new Date(t.ts*1000)
                    }
                    data2.push(tmp)
                }
            }
            //console.log("DATA1",data,"DATA2",data2)
            if(this.chart1){
                this.chart1.data = data;
                this.chart1.invalidateData();
                this.chart2.data = data2;
                this.chart2.invalidateData();
            }
        }
    },
    watch: {
        mems: function(){
            console.log("MEMS UPDATED")
            this.updateChartData()
        }
    },
    mounted() {
        let chart = am4core.create(this.$refs.chart1div, am4charts.XYChart);
        let chart2 = am4core.create(this.$refs.chart2div, am4charts.XYChart);

        chart.data = []
        chart2.data = []
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;

        let dateAxis2 = chart2.xAxes.push(new am4charts.DateAxis());
        dateAxis2.renderer.grid.template.location = 0;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.minWidth = 35;

        let valueAxis2 = chart2.yAxes.push(new am4charts.ValueAxis());
        valueAxis2.tooltip.disabled = true;
        valueAxis2.renderer.minWidth = 35;

        let series = this.createSeries("x", "Asse X", chart);
        this.createSeries("y", "Asse Y", chart);
        this.createSeries("z", "Asse Z/10", chart);

        chart.cursor = new am4charts.XYCursor();
        chart2.cursor = new am4charts.XYCursor();
        this.createSeries("x", "Asse X", chart2);
        this.createSeries("y", "Asse Y", chart2);
        this.createSeries("z", "Asse Z/10", chart2);

        let scrollbarX = new am4charts.XYChartScrollbar();
        scrollbarX.series.push(series);
        chart.scrollbarX = scrollbarX;
        chart2.scrollbarX = scrollbarX;

        this.chart1 = chart;
        this.chart2 = chart2;
    },
    beforeUnmount() {
        if (this.chart1 && this.chart1.dispose && typeof this.chart1.dispose  === "function") {
            this.chart1.dispose();
            this.chart2.dispose();
        }
    },
    computed: {
        ...mapState(['user', 'mems']),
    }
}
</script>
