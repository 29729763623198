<template>
<Dashboard v-if="isCurrentPage('dashboard') && permessoPresente('3')" />
<Params v-if="isCurrentPage('params') && permessoPresente('4')" />
<Home v-if="isCurrentPage('home')" />
<Firmware v-if="isCurrentPage('firmware') && permessoPresente('7')" />
<Admin v-if="isCurrentPage('admin') && permessoPresente('9')" />
<Racing v-if="isCurrentPage('racing') && permessoPresente('10')" />
<Account v-if="isCurrentPage('account')" />
<Gym v-if="isCurrentPage('gym')" />
<Algo v-if="isCurrentPage('algo')" />
<Login v-show="loggedin==false" @logged="setLoggedIn" />
<Navbar v-show="loggedin==true" :permessi="permessi" @logged="setLoggedIn" />
</template>

<script>
import Dashboard from './views/Dashboard.vue'
import Login from './views/Login.vue'
import Params from './views/Params.vue'
import Home from './views/Home.vue'
import Firmware from './views/Firmware.vue'
import Admin from './views/Admin.vue'
import Account from './views/Account.vue'
import Gym from './views/AlgoGym.vue'
import Algo from './views/Algo.vue'
import Racing from './views/Racing.vue'
import Navbar from './components/Navbar.vue'
import * as API from './main.js'

export default {
    name: 'App',
    components: {
        Dashboard,
        Login,
        Params,
        Home,
        Firmware,
        Racing,
        Admin,
        Account,
        Gym,
        Algo,
        Navbar
    },
    data() {
        return {
            loggedin: false
        }
    },
    beforeCreate() { 
        this.$store.commit('initialiseStore', API.prefix);
    },
    created() {
        let pages = ["dashboard","params","home","firmware","admin","account", "gym", "algo","racing"]
        if(pages.indexOf(this.currentPage)==-1){
            window.location.href = "/home/"
        }
    },
    methods: {
        isCurrentPage(page) {
            if (this.loggedin == true && this.currentPage == page) {
                return true
            } else {
                return false
            }
        },
        permessoPresente(perm){
            if(this.permessi.indexOf(perm)==-1){
                return false
            }else{
                return true
            }
        },
        setLoggedIn(status) {
            //console.log("SETTING LOGGED IN TO ", status)
            this.loggedin = status
            if(status==false){
                this.permessi = []
            }
        },
        setLoginUri() {
            if(!this.isCurrentPage("login")){
                window.history.pushState({}, 'Login', '/login/');
            }
            return true
        }
    },
    computed: {
        currentPage() {
            console.log("GETTING",window.location.pathname)
            let path = window.location.pathname.toLowerCase().split('/')
            if (path[1]) {
                return path[1]
            } else {
                return ""
            }
        },
        permessi() {
            let tmp = this.$store.state.permessi
            if(tmp){
                return tmp
            }else{
                return []
            }
        }
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    overflow-y: hidden;
    overflow-x: hidden;
}
</style>
