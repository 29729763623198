<template>
<div class="d-flex flex-column">
    <div class="d-flex flex-column justify-content-between">
        <main class="d-flex flex-column" style="height:78vh"> <!--justify-content-around-->
            <h2>Stato Dispositivo:</h2>
            <article class="leaderboard__profile">
                <span class="leaderboard__name">Utente: </span> <span class="leaderboard__value">{{this.user.nome_utente}}</span>
                <span class="leaderboard__name">&emsp;Tel: </span><span class="leaderboard__value">{{this.user.telefono || ''}}</span>
            </article>

            <article class="leaderboard__profile">
                <div>
                    <img src="/centralina.png" alt="Centralina" class="leaderboard__picture">
                    <span class="leaderboard__name">Centralina</span>
                    <span class="leaderboard__value">TRILL</span>
                </div>
                <div>
                    <span class="leaderboard__name">Timestamp: </span>
                    <span class="leaderboard__value">{{this.getTs(2)}} </span>
                </div>
                <div>
                    <span class="leaderboard__name">Tensione: </span>
                    <span class="leaderboard__value">{{dati[1].batteria || ""}}v </span>
                    <span class="leaderboard__name">Stato: </span>
                    <span class="leaderboard__value">{{this.disconnesso(new Date(dati[2].ts))}}</span>
                </div>
                <div>
                    <span class="leaderboard__name">Firmware: </span>
                    <span class="leaderboard__value">{{this.user.Firmware || ''}} </span>
                </div>
            </article>

            <article class="leaderboard__profile">
                <div><img src="/gps.png" alt="GPS" class="leaderboard__picture">
                    <span class="leaderboard__name">GPS</span></div>
                <div>
                    <span class="leaderboard__name">Stato: </span>
                    <span class="leaderboard__value">{{this.disconnesso(new Date(dati[0].ts))}} </span>
                </div>
                <div>
                    <span class="leaderboard__name">Timestamp: </span>
                    <span class="leaderboard__value">{{this.getTs(0)}} </span>
                </div>
                <div>
                    <span class="leaderboard__name">Lat: </span>
                    <span class="leaderboard__value">{{this.dati[0].lat}} </span>
                    <span class="leaderboard__name">Lon: </span>
                    <span class="leaderboard__value">{{this.dati[0].lon}} </span>
                    <span class="leaderboard__name">Alt: </span>
                    <span class="leaderboard__value">{{this.dati[0].alt}} </span>
                </div>
            </article>

            <article class="leaderboard__profile">
                <div><img src="/gsm.png" alt="GSM" class="leaderboard__picture">
                    <span class="leaderboard__name">GSM</span></div>
                <div>
                    <span class="leaderboard__name">Segnale: </span>
                    <span class="leaderboard__value">{{this.dati[2].segnale}} </span>
                    <span class="leaderboard__name">APN: </span>
                    <span class="leaderboard__value">{{ this.user.apn || ''}} </span>
                </div>
            </article>
        </main>
    </div>
</div>
</template>

<script>
export default {
    name: 'Centralina',
    props: ["dati", "user"],
    methods: {
        disconnesso(d) {
            let diff = new Date() - this.presentDate(d);
            if (diff > 180000) { //3 minuti
                return "Disconnesso"
            } else {
                return "Connesso"
            }
        },
        presentDate(d) {
            let adesso = new Date()
            while (d > adesso) {
                d.setHours(d.getHours() - 1);
            }
            return d
        },
        getTs(id) {
            let d = new Date(this.dati[id].ts)
            if (!d) {
                return ""
            }
            d = this.presentDate(d)
            d = d.toLocaleString()
            return d
        }
    }
}
</script>

<style>
.leaderboard {
    max-width: 490px;
    width: 100%;
    border-radius: 12px;
    border: 1px;

}

.leaderboard__profiles {
    background-color: red;
    border-radius: 0 0 12px 12px;
    row-gap: 8px;
}

.leaderboard__profile {
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    box-shadow: 0 5px 7px -1px rgba(51, 51, 51, 0.23);
    cursor: pointer;
    transition: transform 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98), box-shadow 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98);
    background-color: #fff;
}

.leaderboard__profile:hover {
    transform: scale(1.03);
    box-shadow: 0 9px 47px 11px rgba(51, 51, 51, 0.18);
}

.leaderboard__picture {
    max-width: 100%;
    width: 30px;
    box-shadow: 0 0 0 5px #ebeef3, 0 0 0 12px #f3f4f6;
    margin: 2vh;
    background: black;
}

.leaderboard__name {
    color: #979cb0;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.64px;
}

.leaderboard__value {
    color: #35d8ac;
    font-weight: 700;
    font-size: 15px;
    text-align: right;
}

.leaderboard__value>span {
    opacity: 0.8;
    font-weight: 600;
    font-size: 11px;
    margin-left: 3px;
}
</style>
