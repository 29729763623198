<template>
    <div class="container-fluid" style="max-height: 90vh; overflow-y: auto;">
        <div class="row">
            <div class="col-12 col-md-1"></div>
            <div class="col-12 col-md-2">
                <Centralina :dati="homedata" :user="userdata" />
            </div>
            <div class="col-12 col-md-8" style="height:80vh">
                <MapMarker :center="center" :icon="icon" :key="center.lat" />
            </div>
        </div>
    </div>
</template>

<script>
import MapMarker from '../components/MapMarker.vue'
import Centralina from '../components/Centralina.vue'
import * as API from '../main.js'

export default {
    name: 'Home',
    components: {
        MapMarker,
        Centralina
    },
    created(){
        let _this = this
        setInterval(function(){
            API.getHome()
            if(_this.userdata.mac_address.length==0){
                API.getUser()
            }
        }, 2000)
    },
    computed: {
        homedata(){
            let tmp = this.$store.state.home
            if(tmp && tmp[0] && tmp[0].lat){
                this.setCenter(tmp[0].lat, tmp[0].lon)
                if(tmp[2].stato>80){
                    this.setIcon('/red.png')
                }else{
                    this.setIcon('/green.png')
                }
                return tmp
            }else{
                return [{alt:0,lat:0,lon:0,ts:0},{batteria:0,ts:0},{segnale:0,stato:80,ts:0}]
            }
        },
        userdata(){
            let tmp = this.$store.state.user
            if(tmp&&tmp.email){
                return tmp
            }else{
                return {email:'',telefono: '', Firmware: '', apn: '', mac_address: ''}
            }
        }
    },
    methods: {
        setCenter(lat, lng){
            this.center.lat = lat
            this.center.lng = lng
        },
        setIcon(str){
            this.icon = str
        }
    },
    data() {
        return {
            center: {
                lat: 45.4,
                lng: 9.17
            },
            icon: '/green.png'
        }
    }
}
</script>

<style>
body{
    overflow-y: hidden;
}
.container{
    margin-top: 2vh;
}
.container-fluid{
    margin-top: 4vh;
}
</style>