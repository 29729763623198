<template>
<div v-if="category==''" class="row">
    <AlgoSelection @changeCategory="changeCategory" />
</div>
<div v-if="category=='algoparams'">
    <div style="height: 5vh"></div>
    <AlgoParams />
    <div class="card back" style="margin-left: 10vw; margin-top: 5vh;">
        <h2 class="card__title">Annulla</h2>
        <p class="card__apply"><a class="link" @click="changeCategory('')">Torna indietro..
            <font-awesome-icon icon="arrow-right" /></a></p>
    </div>
</div>
<div v-else class="row">
    <AlgoDetail :category="category" @changeCategory="changeCategory" />
</div>
</template>

<script>

import AlgoSelection from '../components/AlgoSelection.vue'
import AlgoDetail from '../components/AlgoDetail.vue'
import AlgoParams from '../components/AlgoParams.vue'
export default {
    name: 'Algo',
    components: {
        AlgoSelection,
        AlgoDetail,
        AlgoParams
    },
    methods: {
        changeCategory(cat){
            this.category=cat
        }
    },
    data() {
        return {
            category: ""
        }
    }
}
</script>
