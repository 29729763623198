<template>
<div class="container-fluid" style="max-height: 90vh; max-width: 95%; overflow-y: auto;">
    <div class="row" :key="reloadKey">
        <!-- Second Part (Map) -->
        <div class="col-12 col-md-9 order-md-2">
            <div class="row">
                <div class="col">
                    <Map :gpspath="gpsdata" />
                </div>
            </div>
            <br>
            <br>
        </div>
        <!-- First Part -->
        <div class="col-12 col-md-3 order-md-1">
            <div class="row">
                <div class="col">
                    <TrackList :trackdata="trackdata" @changetrack="changetrack" @deletetrack="deletetrack"/>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <br>
                    <div class="row pad-v">
                        <h2>Storico Allarmi</h2>
                    </div>
                    <div class="row pad-v">
                        <textarea v-model="this.cadute" disabled style="min-height: 25vh; max-height: 60vh; overflow-y: scroll" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row rowspace">
        <h2>Profilo Altimetrico</h2>
        <div ref="chart1div" class="chart"></div>
    </div>
    <div class="row rowspace">
        <h2>Velocità</h2>
        <div ref="chart2div" class="chart"></div> 
    </div>
    <div style="height: 5vh"></div>
</div>
</template>

<script>
import Map from '../components/Map.vue'
//import store from '../store';
import TrackList from '../components/TrackList.vue'
import * as API from '../main.js'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
am4core.options.autoDispose = true;

export default {
    name: 'Dashboard',
    components: {
        Map,
        TrackList
    },
    methods: {
        changetrack(index) {
            this.index = index
            this.gpsdata = this.trackdata[this.index]
            this.updateChartData()
        },
        deletetrack(index){
            this.index= index
            let id=this.trackdata[index].deviceid
            let idtraccia=this.trackdata[index]._id
            console.log("id device = " + id)
            console.log("id traccia da eliminare = " + idtraccia)
            console.log("prima API: " , this.trackdata)
            API.deleteTrack(id, idtraccia)
            console.log("dopo API: " , this.trackdata)
            this.trackdata.splice(index,1)
            console.log("dopo splice: " , this.trackdata)
            this.reloadKey += 1;
        },
        createSeries(field, name, chart) {
            let series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = field;
            series.dataFields.dateX = "date";
            series.name = name;
            series.tooltipText = "{dateX.formatDate('HH:mm:ss')}: {name} [b]{valueY}[/]";
            series.strokeWidth = 2;
            let lineseries = chart.series.push(new am4charts.LineSeries());
            lineseries.dataFields.valueY = field;
            lineseries.dataFields.dateX = "date";
            lineseries.strokeWidth = 2;
            if (field == "spd")
                series.stroke = am4core.color("#ff0000");
            return series
        },
        distancegeoid(lat1, lon1, lat2, lon2) {
			// Convert degrees to radians
			lat1 = lat1 * Math.PI / 180.0;
			lon1 = lon1 * Math.PI / 180.0;
			lat2 = lat2 * Math.PI / 180.0;
			lon2 = lon2 * Math.PI / 180.0;

			// radius of earth in metres
			var r = 6378100;
			// P
			var rho1 = r * Math.cos(lat1);
			var z1 = r * Math.sin(lat1);
			var x1 = rho1 * Math.cos(lon1);
			var y1 = rho1 * Math.sin(lon1);
			// Q
			var rho2 = r * Math.cos(lat2);
			var z2 = r * Math.sin(lat2);
			var x2 = rho2 * Math.cos(lon2);
			var y2 = rho2 * Math.sin(lon2);
			// Dot product
			var dot = (x1 * x2 + y1 * y2 + z1 * z2);
			var cos_theta = dot / (r * r);
			var theta = Math.acos(cos_theta);
			// Distance in Metres
			return r * theta;
		},
        updateChartData() {
            let data = []
            let gpstmp = []
            if(this.gpsdata.path && this.gpsdata.path.length>20)
                gpstmp = JSON.parse(JSON.stringify(this.gpsdata.path.filter(x=>x.mark200==true)))
            else
                gpstmp = JSON.parse(JSON.stringify(this.gpsdata.path))
            for(let x of gpstmp){
              x.updateTime = new Date(x.updateTime)
            }
            for (let t of gpstmp) {
                if (t.updateTime) {
                    let tmp = {
                        value: t.alt,
                        date: new Date(t.updateTime)
                    }
                    data.push(tmp)
                }
            }

            let data2 = []
            let prev = false;
            for (let t of gpstmp) {
                if(prev==false){
                    prev = t
                    continue
                }
                if (t.updateTime) {
                    let update = new Date(t.updateTime)
                    let diff = (update - new Date(prev.updateTime))/1000
                    if(diff==0){
                        diff = 1
                    }
                    let speed = (this.distancegeoid(prev.lat,prev.lng,t.lat,t.lng)/diff)*3.6
                    if(speed > 150){
                        speed = 0
                    }
                    let tmp = {
                        spd: speed,
                        date: update
                    }
                    data2.push(tmp)
                    prev = t
                }
            }
            if(this.chart1){
                this.chart1.data = data;
                this.chart1.invalidateData();
                this.chart2.data = data2;
                this.chart2.invalidateData();
            }
        }
    },
    data() {
        return {
            index: 0,
            chart1div: {},
            chart2div: {},
            interval: -1,
            gpsdata: {},
            reloadKey: 0,
        }
    },
    created() {
        let _this = this
        API.getCadute().then(tmp => {
            let tmpstr = ""
            for (let t of tmp) {
                tmpstr += new Date(t["data"]).toLocaleString('it-it') + "\n"
            }
            this.cadute = tmpstr
        }).catch(e => {
            console.log(e)
        })
        this.interval = setInterval(function () {
            if (_this.trackdata.length <= 1) {
                API.getTracks()
                clearInterval(_this.interval)
            }
        }, 1000)

    },
    mounted() {
        let chart = am4core.create(this.$refs.chart1div, am4charts.XYChart);
        let chart2 = am4core.create(this.$refs.chart2div, am4charts.XYChart);

        chart.data = []
        chart2.data = []
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;

        let dateAxis2 = chart2.xAxes.push(new am4charts.DateAxis());
        dateAxis2.renderer.grid.template.location = 0;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.minWidth = 35;

        let valueAxis2 = chart2.yAxes.push(new am4charts.ValueAxis());
        valueAxis2.tooltip.disabled = true;
        valueAxis2.renderer.minWidth = 35;

        let series = this.createSeries("value", "Profilo altimetrico", chart);

        series.tooltipText = "{valueY.value}";
        chart.cursor = new am4charts.XYCursor();
        chart2.cursor = new am4charts.XYCursor();
        this.createSeries("spd", "Velocità", chart2);

        let scrollbarX = new am4charts.XYChartScrollbar();
        scrollbarX.series.push(series);
        chart.scrollbarX = scrollbarX;
        chart2.scrollbarX = scrollbarX;

        this.chart1 = chart;
        this.chart2 = chart2;
    },
    beforeUnmount() {
        if (this.chart1 && this.chart1.dispose && typeof this.chart1.dispose  === "function") {
            this.chart1.dispose();
            this.chart2.dispose();
        }
    },
    computed: {
        trackdata() {
            let tmp = this.$store.state.tracks
            if (tmp && tmp.length) {
                console.log(tmp.length + " TRACKS AVAILABLE")
                tmp = tmp.filter(x => x.path.length > 2)
                for (let t of tmp) {
                    t.color = API.getRandomColor()
                }
                return tmp;
            } else {
                return [{
                    "path": [{}]
                }]
            }

        },
    }
}
</script>

<style>
.chart {
    width: 100%;
    height: 30vh;
}

.rowspace {
    margin-top: 5vmax !important;
    margin-bottom: 1vmax;
}

.container.fluid{
    margin-top: 5vh;
    height: 85vh;
    overflow-y: scroll;
}

.scroll1::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}
</style>
