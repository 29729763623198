import { createApp, h } from 'vue'
import App from './App.vue'
import store from './store';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "bootstrap-icons/font/bootstrap-icons.css"
require('dotenv').config();

import { library } from "@fortawesome/fontawesome-svg-core";
import { faBicycle, faMotorcycle, faCar, faArrowRight, faAmbulance, faMale, faFootballBall, faHardHat, faUserShield, faFemale } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faBicycle);
library.add(faMotorcycle);
library.add(faCar);
library.add(faFemale);
library.add(faArrowRight);
library.add(faAmbulance);
library.add(faMale);
library.add(faFootballBall);
library.add(faHardHat);
library.add(faUserShield);

export const apiurl = process.env.VUE_APP_APIURL;
export const prefix = process.env.VUE_APP_PREFIX;

console.log("PREFIX: ", prefix, " APIURI: ", apiurl)

import sharedArray from './trilldonnaalgs';

export const doAPIcall = async function (uri, query = null) {
    uri = apiurl + uri
    if (query) {
        uri += "?" + query
    }
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" }
    };
    //console.log("FETCHING ",uri,requestOptions)
    return fetch(uri, requestOptions)
        .then(response => response.json())
        .then(data => {
            if (data.status == "ok") {
                if (data.Bearer) {
                    console.log(data)
                    localStorage.setItem(prefix + "token", data.Bearer)
                    store.commit('setpermessi', [data.Permessi, prefix])

                    let expiry = new Date(Date.now() + 24 * 60 * 60 * 1000)
                    localStorage.setItem(prefix + "expiry", expiry.getTime())
                    console.log("LOGGED IN")
                    getUser()
                    getTracks()
                    getHome()
                }
                if (data.listaUtenti) {
                    store.commit('setlistautenti', data.listaUtenti)
                }
                if (data.userdata) {
                    store.commit('setuser', data.userdata)
                }
                if (data.fwdata) {
                    store.commit('setfw', data.fwdata)
                }
                if (data.data) {
                    if (data.data.deletedCount){
                        console.log("TRACK DELETED")
                        //store.commit('deletetrack', data.data.deletedCount)
                    } else{
                        store.commit('settracks', data.data)
                        console.log("TRACKS DATA RETRIEVED")
                    }
                }
                if (data.mems){
                    store.commit('setmems', data.mems)
                }
                if (data.homedata) {
                    store.commit('sethome', data.homedata)
                }
                if (data.cadute) {
                    return data.cadute
                }
            } else {
                alert("Nessun dato trovato!")
                /*console.log("API FAILURE, LOGGING OUT")
                console.log(data)
                localStorage.removeItem(prefix + "token")
                localStorage.removeItem(prefix + "expiry")
                localStorage.removeItem(prefix + "permessi")
                location.reload()*/
            }
        }
        ).catch(function (e) {
            console.log("API CALL ERROR", uri)
            console.log(e);
            return
        });
}

export const salvaAccount = async function (oldpwd, email, tel, pass, permessi, cliente, abilitato, id) {
    await dorenew()
    let token = localStorage.getItem(prefix + "token")
    let query = ""
    if (pass) {
        query = "token=" + token + "&oldpwd=" + oldpwd + "&email=" + email + "&tel=" + tel + "&pass=" + pass
    } else {
        query = "token=" + token + "&oldpwd=" + oldpwd + "&email=" + email + "&tel=" + tel
    }
    if(permessi){
        query+="&admin=true&permessi="+permessi+"&cliente="+cliente+"&abilitato="+abilitato+"&id="+id
    }
    console.log(query)
    doAPIcall("/updateUser", query)
}

export const addAccount = async function (nome, permessi, email, tel, pass, cliente, abilitato) {
    await dorenew()
    let token = localStorage.getItem(prefix + "token")
    let query = "token=" + token + "&nome="+nome+"&permessi=" + permessi + "&email=" + email + "&tel=" + tel + "&pass=" + pass + "&cliente=" + cliente + "&abilitato=" + abilitato
    if(nome!="")
        doAPIcall("/addUser", query)
}

export const deleteAccount = async function(id) {
    await dorenew()
    let token = localStorage.getItem(prefix + "token")
    let query = "token=" + token + "&id="+id
    doAPIcall("/deleteUser", query)
}

export const getMems = async function(date) {
    await dorenew()
    let token = localStorage.getItem(prefix + "token")
    let query = "token=" + token + "&datefrom="+date
    doAPIcall("/getMems", query)
}

export const addTrill = async function(id, mac, nome, apn, descr) {
    await dorenew()
    let token = localStorage.getItem(prefix + "token")
    let query = "token=" + token + "&id="+id+"&mac="+mac+"&nome="+nome+"&apn="+apn+"&descr="+descr
    doAPIcall("/addTrill", query)
}

export const editTrill = async function(id,mac, nome, apn, descr) {
    await dorenew()
    let token = localStorage.getItem(prefix + "token")
    let query = "token=" + token + "&id="+id+"&mac="+mac+"&nome="+nome+"&apn="+apn+"&descr="+descr
    doAPIcall("/editTrill", query)
}

export const deleteTrill = async function(id) {
    await dorenew()
    let token = localStorage.getItem(prefix + "token")
    let query = "token=" + token + "&id="+id
    console.log(query)
    doAPIcall("/deleteTrill", query)
}

export const updateUser = function (data) {
    store.commit('setuser', data);
}
export const setUri = function (uri, text) {
    window.history.pushState({}, text, uri);
}
export const getRandomColor = function () {
    var letters = '0123456789ABCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export const dorenew = async function () {
    let expir = localStorage.getItem(prefix + "expiry")
    if (expir && parseInt(expir) < Date.now()) {
        console.log("RENEW")
        let token = localStorage.getItem(prefix + "token")
        await doAPIcall("/renew", "token=" + token)
    }
}
export const dologout = function () {
    let token = localStorage.getItem(prefix + "token")
    if (token) {
        doAPIcall("/logout", "token=" + token)
        localStorage.removeItem(prefix + "token")
        localStorage.removeItem(prefix + "user")
        localStorage.removeItem(prefix + "pass")
        localStorage.removeItem(prefix + "expiry")
        localStorage.removeItem(prefix + "permessi")
    }
}
export const dologin = function (user, pass) {
    console.log("DOING LOGIN")
    doAPIcall("/login", "User=" + user + "&Pass=" + pass)
}
export const getTracks = async function () {
    await dorenew()
    let token = localStorage.getItem(prefix + "token")

    if (token) {
        console.log("GETTING TRACKS")
        doAPIcall("/getTracks", "token=" + token)
    } else {
        console.log("NOT GETTING TRACKS")
    }
}

export const deleteTrack = async function (id, idtraccia) {
    await dorenew()
    let token = localStorage.getItem(prefix + "token")
    let query = "token=" + token + "&id="+id + "&idtraccia=" + idtraccia
    doAPIcall("/deleteTrack", query)
}

export const getListaUtenti = async function () {
    await dorenew()
    let token = localStorage.getItem(prefix + "token")

    if (token) {
        console.log("GETTING getListaUtenti")
        doAPIcall("/getListaUtenti", "token=" + token)
    } else {
        console.log("NOT GETTING getListaUtenti")
    }
}

export const getHome = async function () {
    await dorenew()
    let token = localStorage.getItem(prefix + "token")

    if (token) {
        doAPIcall("/getHomeData", "token=" + token)
    } else {
        console.log("NOT GETTING HOME")
    }
}

export const getFW = async function () {
    await dorenew()
    let token = localStorage.getItem(prefix + "token")

    if (token) {
        doAPIcall("/getFW", "token=" + token)
    } else {
        console.log("NOT GETTING FW")
    }
}

export const setTrillAgg = async function (id, reset) {
    await dorenew()
    let token = localStorage.getItem(prefix + "token")

    if (token) {
        doAPIcall("/setTrillAgg", "token=" + token + "&id=" + id + "&reset=" + reset)
    } else {
        console.log("NOT GETTING FW")
    }
}

export const setParams = async function (msg) {
    await dorenew()
    let token = localStorage.getItem(prefix + "token")

    if (token) {
        console.log("SETTING PARAMS")
        doAPIcall("/setParams", "token=" + token + "&msg=" + msg)
    } else {
        console.log("NOT GETTING SETP")
    }
}

export const readParams = async function (msg, type = "read") {
    await dorenew()
    let token = localStorage.getItem(prefix + "token")

    if (token) {
        console.log("READING PARAMS")
        doAPIcall("/readParams", "token=" + token + "&msg=" + msg + "&type=" + type)
    } else {
        console.log("NOT GETTING READP")
    }
}

export const getUser = async function () {
    await dorenew()
    let token = localStorage.getItem(prefix + "token")

    if (token) {
        console.log("GETTING USER")
        doAPIcall("/getUserData", "token=" + token)
    } else {
        console.log("NOT GETTING HOME")
    }
}

export const getCadute = async function () {
    await dorenew()
    let token = localStorage.getItem(prefix + "token")

    if (token) {
        console.log("GETTING CADUTE")
        return doAPIcall("/getCadute", "token=" + token).then(x => {
            return x
        }).catch(e => {
            Promise.reject(e)
        })
    } else {
        console.log("NOT GETTING CADUTE")
    }
}

let user = localStorage.getItem(prefix + "user")
console.log("USER:", user)
let token = localStorage.getItem(prefix + "token")
console.log("TOKEN:", token)
if (user && user.length > 0 && (token == null || token == "null")) {
    let pass = localStorage.getItem(prefix + "pass")
    dologin(user, pass)
} 

import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'

const app = createApp({ render: () => h(App) });
app.use(store)
app.component("font-awesome-icon", FontAwesomeIcon)
app.component("Datepicker", Datepicker);

// Provide the shared array as a global property
app.config.globalProperties.$sharedArray = sharedArray;

app.mount('#app');