<template>
<GoogleMap ref="mapRef" api-key="AIzaSyDmUuIvxlVn47zI3m20B3dm4-xer66ibak" style="width: 100%; height: 70vh" :center="center" :zoom="15">
    <Polyline :options="path" :key="componentReload" />
</GoogleMap>
<vue3-slider v-model="slidercounter" :step="sliderStep" :height="10" color="#990000" track-color="#FEFEFE" />
</template>

<script>
import slider from "vue3-slider"
import {
    defineComponent,
    ref
} from 'vue'
import {
    GoogleMap,
    Polyline
} from 'vue3-google-map'

export default defineComponent({
    components: {
        GoogleMap,
        Polyline,
        "vue3-slider": slider
    },
    setup() {
        const mapRef = ref(null)

        const center = ref({ lat: 45, lng: 9 })

        function centermap(start, end, center) {
            if (mapRef.value ?.ready) {
                const gmap = mapRef.value.map;
                const api = mapRef.value.api;
                if(center){
                    console.log("CENTER")
                    console.log("S: "+start,"E: "+end)
                    let center = new api.LatLng(start,end)
                    gmap.setCenter(center)
                    return
                }
                console.log("NOT CENTER")
                this.bounds = new api.LatLngBounds();
                this.bounds.extend(start);
                this.bounds.extend(end);
                gmap.fitBounds(this.bounds);
            } else {
                console.log("NOT READY")
            }
        }
        const startSymbol = {
            path: "M -2,0 0,-2 2,0 0,2 z",
            strokeColor: "#F00",
            fillColor: "#F00",
            fillOpacity: 1,
        }
        const pointSymbol = {
            path: "M -1 1 m -1, 0 a 1,1 0 1,0 2,0 a 1,1 0 1,0 -2,0",
            strokeColor: "#F00",
            fillColor: "#F00",
            strokeWeight: 1,
            fillOpacity: 1
        }
        const activeSymbol = {
            //path: "M -2 2 m -2, 0 a 2,2 0 2,0 2,0 a 2,2 0 2,0 -2,0",
            path: "M17.218,2.268L2.477,8.388C2.13,8.535,2.164,9.05,2.542,9.134L9.33,10.67l1.535,6.787c0.083,0.377,0.602,0.415,0.745,0.065l6.123-14.74C17.866,2.46,17.539,2.134,17.218,2.268 M3.92,8.641l11.772-4.89L9.535,9.909L3.92,8.641z M11.358,16.078l-1.268-5.613l6.157-6.157L11.358,16.078z",
            strokeColor: "#000000",
            fillColor: "#000000",
            strokeWeight: 1,
            fillOpacity: 1,
            scale: 1.5
        }
        const endSymbol = {
            path: "M -2,-2 2,2 M 2,-2 -2,2",
            strokeColor: "#292",
            strokeWeight: 4,
        } 
        
        return {
            mapRef,
            centermap,
            center,
            startSymbol,
            endSymbol,
            pointSymbol,
            activeSymbol
        }
    },
    watch: {
        slidercounter: function (count) {
            if (this.gpspath && this.gpspath.path) {
                let pos = Math.round(this.sliderStep * count)
                this.path.icons[1].offset = count + '%'
                this.componentReload+=1;
                //console.log("SETTING POS TO "+count, this.path.icons[1].offset)
                try {
                    if(this.gpspath.path[pos]){
                        this.centermap(this.gpspath.path[pos].lat, this.gpspath.path[pos].lng,true)
                        this.center = {lat: this.gpspath.path[pos].lat,lng: this.gpspath.path[pos].lng}
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        },
        gpspath: function (gpspath) {
            console.log("RECOMPUTING PATH")
            if (gpspath && gpspath.path) {
                let filteredpath = gpspath.path.filter(x => Math.abs(x.lat) > 1)
                let m200 = filteredpath.filter(x => x.mark200 == true).length
                let percent = 0
                let count = 0
                if (m200 > 20) {
                    let m500 = filteredpath.filter(x => x.mark500 == true).length
                    if (m500 > 20) {
                        let m1000 = filteredpath.filter(x => x.mark1000 == true).length
                        if (m1000 > 20) {
                            let m2000 = filteredpath.filter(x => x.mark2000 == true).length
                            count = m2000
                        } else {
                            count = m1000
                        }
                    } else {
                        count = m500
                    }
                } else {
                    count = m200
                }
                let icone = []
                percent = 100 / count
                this.changestep(percent)
                icone.push({
                    icon: this.startSymbol,
                    offset: "0%"
                })
                icone.push({
                    icon: this.activeSymbol,
                    offset: this.slidercounter + '%'
                })
                for (var i = percent; i < 100; i += percent) {
                    icone.push({
                        icon: this.pointSymbol,
                        offset: i + "%"
                    })
                }
                icone.push({
                    icon: this.endSymbol,
                    offset: "100%"
                })
                if (filteredpath && filteredpath.length > 1)
                    this.centermap(filteredpath[0], filteredpath[filteredpath.length - 1])
                this.path = {
                    path: filteredpath,
                    strokeColor: gpspath.color,
                    icons: icone
                }
            } else {
                this.path = {
                    path: [],
                    strokeColor: "#ffffff"
                }
            }
        }
    },
    methods: {
        changestep(step) {
            this.sliderStep = step
        }
    },
    data() {
        return {
            bounds: null,
            loaded: false,
            slidercounter: 0,
            sliderStep: 1,
            path: {
                path: [],
                strokeColor: "#ffffff",
                icons: [{icon:"",offset:""},{icon:"",offset:""}]
            },
            componentReload: 0
        }
    },
    props: {
        "gpspath": {
            type: Object
        }
    }
})
</script>
