<template>
<div class="container">
    <div class="row">
        <div class="col-12 col-md-3">
            <div class="row pad-v">
                <h2>Dati Utente</h2>
            </div>
            <div class="row pad-v">
                Username:
            </div>
            <div class="row pad-v">
                <input v-model="this.userdata.nome_utente" type="text" class="form-control" disabled />
            </div>
            <div class="row pad-v">
                Email:
            </div>
            <div class="row pad-v">
                <input v-model="this.userdata.email" type="text" autocomplete="email" class="form-control" />
                <input type="text" autocomplete="username" style="visibility: hidden; height: 0px" />
            </div>
            <div class="row pad-v">
                Telefono:
            </div>
            <div class="row pad-v">
                <input v-model="this.userdata.telefono" type="text" autocomplete="tel" class="form-control" />
            </div>
        </div>
        <div class="col-12 col-md-3 mar-or">
            <div class="row pad-v">
                <h2>Cambio Password</h2>
            </div>
            <div class="row pad-v">
                Vecchia password:
            </div>
            <div class="row pad-v">
                <input v-model="this.oldpassword" type="password" autocomplete="current-password" class="form-control" />
            </div>
            <div class="row pad-v">
                Nuova password:
            </div>
            <div class="row pad-v">
                <input v-model="this.newpass" type="password" autocomplete="new-password" class="form-control" />
            </div>
            <div class="row pad-v">
                Nuova password (conferma):
            </div>
            <div class="row pad-v">
                <input v-model="this.confirmnewpass" type="password" autocomplete="new-password" class="form-control" />
            </div>
        </div>
        <div class="col-12 col-md-3">
            <div class="row pad-v">
                <h2>Storico Allarmi</h2>
            </div>
            <div class="row pad-v">&nbsp;</div>
            <div class="row pad-v">
                <textarea v-model="this.cadute" disabled style="min-height: 25vh; max-height: 60vh; overflow-y: scroll" />
                </div>
            </div>
        </div>
        <div class="row pad-v">
            <div class="col col-4">
                <div class="row pad-v">&nbsp;</div>
                <div class="row pad-v">
                    <button class="btn btn-success" @click="this.salva">Salva</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import * as API from '../main.js'

export default {
    name: 'Account',
    created() {
        let _this = this
        setTimeout(function () {
            if (_this.userdata.mac_address.length == 0) {
                API.getUser()
            }
        }, 2000)
        API.getCadute().then(tmp => {
            let tmpstr = ""
            for (let t of tmp) {
                tmpstr += new Date(t["data"]).toLocaleString('it-it') + "\n"
            }
            this.cadute = tmpstr
        }).catch(e => {
            console.log(e)
        })
    },
    methods: {
        salva(){
            if(this.newpass.length>0){
                console.log("SALVA PASSWORD EDITED")
                if(this.newpass==this.confirmnewpass && this.newpass != this.oldpassword){
                    console.log("CHECK OK")
                    API.salvaAccount(this.oldpassword, this.userdata.email, this.userdata.telefono, this.newpass)
                    alert("DATI ACCOUNT AGGIORNATI")
                }else{
                    alert("Nuova password non valida!")
                }
            }else{
                console.log("SALVA NO PASSWORD")
                API.salvaAccount(this.oldpassword, this.userdata.email, this.userdata.telefono)
                alert("EMAIL/TELEFONO aggiornati!")
            }
            return
        }
    },
    computed: {
        userdata() {
            let tmp = this.$store.state.user
            if (tmp && tmp.email) {
                return tmp
            } else {
                return {
                    email: '',
                    telefono: '',
                    Firmware: '',
                    apn: '',
                    mac_address: '',
                    nome_utente: ''
                }
            }
        }
    },
    data() {
        return {
            cadute: "",
            oldpassword: "",
            newpass: "",
            confirmnewpass: ""
        }
    }
}
</script>

<style>
.mar-or {
    margin-left: 20px;
    margin-right: 20px;
}

.pad-v {
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>
