import { createStore } from 'vuex';
//import { deleteTrack } from './main';

export default createStore({
  state: {
    tracks: [],
    home: [],
    permessi: [],
    fw: [],
    user: {},
    listautenti: [],
    mems: []
  },
  actions: {
    onSettracks: ({ commit }, tracks) => {
      commit('settracks', tracks);
    },
    onDeleteTrack: ({ commit }, index) => {
      commit('deletetrack', index);
    },
    onSethome: ({ commit }, home) => {
      commit('sethome', home);
    },
    onSetpermessi: ({ commit }, permessi) => {
      commit('setpermessi', permessi);
    },
    onSetuser: ({ commit }, user) => {
      commit('setuser', user);
    },
    onSetfw: ({ commit }, fw) => {
      commit('setfw', fw);
    },
    onSetMems: ({ commit }, mems) => {
      commit('setmems', mems);
    },
    onSetlistautenti: ({ commit }, listautenti) => {
      commit('setlistautenti', listautenti);
    }
  },
  mutations: {
    settracks: (state, tracks) => {
      let backtracks = tracks.reverse()
      state.tracks = backtracks;
      //console.log(backtracks[0])
    },
    deletetrack: (state, id) => {
      console.log("id traccia: ", id)
    },
    sethome: (state, home) => {
      state.home = home;
    },
    initialiseStore(state, prefix) {
      let tmp = localStorage.getItem(prefix+"permessi")
      if (tmp) {
        state.permessi = tmp;
      }
    },
    setpermessi: (state, permessi) => {
      let prefix = permessi[1]
      localStorage.setItem(prefix+"permessi", permessi[0])
      state.permessi = permessi[0];
    },
    setuser: (state, user) => {
      state.user = user;
    },
    setfw: (state, fw) => {
      state.fw = fw;
    },
    setmems: (state, mems) => {
      state.mems = mems;
    },
    setlistautenti: (state, listautenti) => {
      state.listautenti = listautenti;
    }
  }
});
