<template>
<GoogleMap ref="mapRef" api-key="AIzaSyDmUuIvxlVn47zI3m20B3dm4-xer66ibak" style="width: 100%; height: 100%" :center="center" :zoom="15">
    <Marker :options="markerPos" />
</GoogleMap>
</template>

<script>
import {
    GoogleMap,
    Marker
} from 'vue3-google-map'

export default ({
    components: {
        GoogleMap,
        Marker
    },
    computed: {
        markerPos(){
            return { position: this.center, icon: this.icon }
        }
    },
    props: {
        "center": {},
        "icon": String
    }
})
</script>
