<template>
    <div class="container" style="max-width: 70%; max-height: 90vh; overflow-y: auto;">
        <div style="margin-top: 3vh; margin-bottom: 1vh; padding: 8px;"><h4><input type="text" v-model="newItem" placeholder='Nome Algoritmo' class="inptextalgo"></h4></div>
        <div style="margin-top: 3vh; margin-bottom: 1vh; padding: 8px;"><h4><input type="text" placeholder='Formula Algoritmo' class="inptextalgo"></h4></div>
        
        <table class="table table-hover" id="tableParamFisici">
            <h3>Parametri fisici</h3>
            <thead>
                <tr>
                <th scope="col">&nbsp;NOME PARAMETRO</th>
                <th scope="col">VALORE PARAMETRO</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th class="align-middle" scope="row">Fondo scala accelerometri</th>
                    <td class="align-middle"><input type="text" class="form-control" style="max-width:70px; display: inline-block; text-align: center;" />&nbsp;</td>
                </tr>
                <tr class="table-active">
                    <th class="align-middle" scope="row">Divisore ODR accelerometri</th>
                    <td class="align-middle"><input type="text" class="form-control" style="max-width:70px; display: inline-block; text-align: center;"/>&nbsp;</td>
                </tr>
                <tr>
                    <th class="align-middle" scope="row">Fondo scala giroscopi</th>
                    <td class="align-middle"><input type="text" class="form-control" style="max-width:70px; display: inline-block; text-align: center;" />&nbsp;</td>
                </tr>
                <tr class="table-active">
                    <th class="align-middle" scope="row">Divisore ODR giroscopi</th>
                    <td class="align-middle"><input type="text" class="form-control" style="max-width:70px; display: inline-block; text-align: center;" />&nbsp;</td>
                </tr>
                <tr>
                    <th class="align-middle" scope="row">WakeUp Motion</th>
                    <td class="align-middle"><input type="text" class="form-control" style="max-width:70px; display: inline-block; text-align: center;" />&nbsp;</td>
                </tr>
                <tr class="table-active">
                    <th class="align-middle" scope="row">Soglia WakeUp Motion</th>
                    <td class="align-middle"><input type="text" class="form-control" style="max-width:70px; display: inline-block; text-align: center;" />&nbsp;</td>
                </tr>
            </tbody>
        </table>
        <button class="buttonplus" @click="addRowToParamFisici()">+</button>
        <br>
        <br>
        
        <table class="table table-hover" id="tableParamAlgoritmo">
            <h3>Parametri Algoritmo</h3>
            <thead>
                <tr>
                <th scope="col">&nbsp;NOME PARAMETRO</th>
                <th scope="col">VALORE PARAMETRO</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th class="align-middle" scope="row">Alpha_HI</th>
                    <td class="align-middle"><input type="text" class="form-control" style="max-width:70px; display: inline-block; text-align: center;" />&nbsp;</td>
                </tr>
                <tr class="table-active">
                    <th class="align-middle" scope="row">Alphav_HI</th>
                    <td class="align-middle"><input type="text" class="form-control" style="max-width:70px; display: inline-block; text-align: center;"/>&nbsp;</td>
                </tr>
                <tr>
                    <th class="align-middle" scope="row">FreeTh_HI</th>
                    <td class="align-middle"><input type="text" class="form-control" style="max-width:70px; display: inline-block; text-align: center;" />&nbsp;</td>
                </tr>
                <tr class="table-active">
                    <th class="align-middle" scope="row">Th_HI</th>
                    <td class="align-middle"><input type="text" class="form-control" style="max-width:70px; display: inline-block; text-align: center;" />&nbsp;</td>
                </tr>
                <tr>
                    <th class="align-middle" scope="row">RecoverTh_HI</th>
                    <td class="align-middle"><input type="text" class="form-control" style="max-width:70px; display: inline-block; text-align: center;" />&nbsp;</td>
                </tr>
                <tr class="table-active">
                    <th class="align-middle" scope="row">AlertTime_HI</th>
                    <td class="align-middle"><input type="text" class="form-control" style="max-width:70px; display: inline-block; text-align: center;" />&nbsp;</td>
                </tr>
                <tr>
                    <th class="align-middle" scope="row">AlertTout_HI</th>
                    <td class="align-middle"><input type="text" class="form-control" style="max-width:70px; display: inline-block; text-align: center;" />&nbsp;</td>
                </tr>
                <tr class="table-active">
                    <th class="align-middle" scope="row">RecoveryTout_HI</th>
                    <td class="align-middle"><input type="text" class="form-control" style="max-width:70px; display: inline-block; text-align: center;" />&nbsp;</td>
                </tr>
            </tbody>
        </table>
        <button class="buttonplus" @click="addRowToParamAlgoritmo()">+</button>
        <br>
        <br>
        <br>
        <br>
        <button class="button" @click="addItemToSportArray">Salva Algoritmo</button>
        <br>
        <br>
        <br>
        
    </div>
</template>

<script>
import { updateArray } from '../trilldonnaalgs.js';

export default {
    name: 'AlgoGym',
    emits: ["addtosport"],
    methods: {
        addItemToSportArray() {
            console.log("nuovo item: " + this.newItem)
            updateArray(this.newItem)
            this.newItem = '';
            alert("Algoritmo aggiunto correttamente!");
        },
        addRowToParamAlgoritmo() {
            var table = document.getElementById("tableParamAlgoritmo");
            var row = table.insertRow(table.rows.length);
            row.innerHTML = `
                <th class="align-middle" scope="row">
                    <input type="text" class="form-control" style="max-width:45%; display: inline-block; text-align: center;"/>
                </th>
                <td class="align-middle">
                    <input type="text" class="form-control" style="max-width:70px; display: inline-block; text-align: center;"/>
                </td>
            `;
        },
        addRowToParamFisici() {
            var table = document.getElementById("tableParamFisici");
            var row = table.insertRow(table.rows.length);
            row.innerHTML = `
                <th class="align-middle" scope="row">
                    <input type="text" class="form-control" style="max-width:45%; display: inline-block; text-align: center;"/>
                </th>
                <td class="align-middle">
                    <input type="text" class="form-control" style="max-width:70px; display: inline-block; text-align: center;" />
                </td>
            `;
        }
    },
    data() {
        return {
            newItem: ''
        };
    }
}
</script>

<style>

.button{
    width: 30%;
    border: 0px;
    color: #fff;
    background-color: rgb(214, 87, 87);
    box-shadow: 0 5px 0 brown;
    border-radius: 5px;
    font-size: 2.5vh;
}

.buttonplus{
    width: 10%;
    border: 0px;
    color: #fff;
    background-color: rgb(214, 87, 87);
    box-shadow: 0 5px 0 brown;
    border-radius: 5px;
    font-size: 2.5vh;
}

.button:active{
    box-shadow: none;
    transform: translate(0, 5px);
}

.inptextalgo{
    border-color: brown;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    font-size: 3vh;
    border-radius: 5px;
}
</style>
    