<template>
<div style="padding-top:15">
    <div class="container-fluid" style="max-width: 90%; max-height: 90vh; overflow-y: auto;">
        <div class="row">
            <div class="col">
                <h2>Admin page</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4">
                <label class="col-sm-12 col-form-label-lg">Utenti:</label>
                <form class="form-horizontal">
                    <div class="form-group row">
                        <div class="col-sm-10">
                            <select v-model="utenteselez" @change="cambiaselez" list="utentelist" id="utentilist" name="utentilist" class="form-control">
                                <option v-for="u in datalista" :key="u">{{u}}</option>
                            </select>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <label class="col-sm-2">Nome:</label>
                        <div class="col-sm-10">
                            <input v-model="selected.nome_utente" id="nome" type="text" class="form-control" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2">Permessi:</label>
                        <div class="col-sm-10">
                            <input v-model="selected.permessi" id="permessi" type="text" class="form-control" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2">Password:</label>
                        <div class="col-sm-10">
                            <input v-model="pwd" id="pwd" type="text" class="form-control" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2">Email:</label>
                        <div class="col-sm-10">
                            <input v-model="selected.email" id="mail" type="text" class="form-control" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2">Telefono:</label>
                        <div class="col-sm-10">
                            <input id="tel" v-model="selected.telefono" type="text" class="form-control" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2">Cliente:</label>
                        <div class="col-sm-10">
                            <input v-model="selected.cliente" id="cliente" type="text" class="form-control" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2">Abilitato:</label>
                        <div class="col-sm-10">
                            <input :checked="selected.abilitato==1 ? true : false" id="abilitato" type="checkbox" />
                        </div>
                    </div>
                    <br>
                    <div class="form-group">
                        <button type="button" @click="addAccount" class="btn btn-success">Aggiungi</button>&nbsp;
                        <button type="button" @click="editAccount" class="btn btn-secondary">Modifica</button>&nbsp;
                        <button type="button" @click="deleteAccount" class="btn btn-danger">Elimina</button>
                    </div>
                </form>
                <br>
            </div>

            <div class="col-12 col-md-4">
                <label class="col-sm-12 col-form-label-lg ">Trill: </label>
                <form class="form-horizontal">
                    <div class="form-group row">
                        <label class="col-sm-2">Nome:</label>
                        <div class="col-sm-10">
                            <input v-model="selected.nome_sensore" id="nometrill" type="text" class="form-control" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2">ID:</label>
                        <div class="col-sm-10">
                            <input v-model="selected.mac_address" id="mac" type="text" class="form-control" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2">Apn:</label>
                        <div class="col-sm-10">
                            <input v-model="selected.apn" id="apn" type="text" class="form-control" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2">Note:</label>
                        <div class="col-sm-10">
                            <input v-model="selected.descrizione" id="note" type="text" class="form-control" />
                        </div>
                    </div>
                    <br>
                    <div class="form-group">
                        <button type="button" @click="addtrill" class="btn fit btn-success">Aggiungi</button>&nbsp;
                        <button type="button" @click="edittrill" class="btn fit btn-secondary">Modifica</button>&nbsp;
                        <button type="button" @click="deletetrill" class="btn fit btn-danger">Elimina</button><br><br>
                    </div>
                </form>
            </div>

            <div class="col-12 col-md-4">
                <label class="col-sm-12 col-form-label-lg ">Permessi:</label> <br>
                1. TBD<br>
                2. TBD<br>
                3. Dashboard<br>
                4. Parametri<br>
                5. TBD<br>
                6. TBD<br>
                7. Firmware<br>
                8. TBD<br>
                9. Admin<br>
                10. Racing <br><br>
                <a href="./export.php"><button class="btn btn-secondary">Dump Database</button></a>
            </div>

        </div>
    </div>
</div>
</template>

<script>
import * as API from '../main.js'
import store from '../store';

export default {
    name: 'Admin',
    data() {
        return {
            index: 0,
            selected: {
                nome_utente: '',
                permessi: '',
                email: '',
                telefono: '',
                cliente: '',
                abilitato: '',
                mac_address: '',
                nome_sensore: '',
                id_sensore: '',
                apn: '',
                descrizione: ''
            },
            utenteselez: '',
            pwd: "\u2022\u2022\u2022\u2022\u2022\u2022",
            initialPWD: "\u2022\u2022\u2022\u2022\u2022\u2022"
        }
    },
    created() {
        API.getListaUtenti()
    },
    methods: {
        updateSelected() {
            this.selected = this.listautenti[this.index]
            this.utenteselez = this.listautenti[this.index].nome_utente
        },
        cambiaselez() {
            let filtered = this.listautenti.filter(x=>x.nome_utente==this.utenteselez)[0]
            if(filtered)
                this.selected = filtered
        },
        addAccount() {
            if(this.initialPWD!=this.pwd){
                API.addAccount(this.selected.nome_utente,this.selected.permessi,this.selected.email,this.selected.telefono, this.pwd, this.selected.cliente, this.selected.abilitato)
                alert("Account aggiunto!")
                API.getListaUtenti()
            }else{
                alert("Inserire prima una password iniziale per il nuovo account!")
            }
        },
        editAccount() {
            if(this.initialPWD!=this.pwd){
                console.log("Salva1")
                API.salvaAccount(null,this.selected.email,this.selected.telefono,this.pwd,this.selected.permessi,this.selected.cliente,this.selected.abilitato, this.selected.IdUtente)
            }else{
                console.log("Salva2")
                API.salvaAccount(null,this.selected.email,this.selected.telefono,null,this.selected.permessi,this.selected.cliente,this.selected.abilitato, this.selected.IdUtente)
            }
            alert("Account modificato!")
            //API.getListaUtenti()
        },
        deleteAccount() {
            var a = confirm("Sicuro di voler eliminare l'account "+this.selected.nome_utente+"?")
            if(a==true){
                API.deleteAccount(this.selected.IdUtente)
                let listautenti = this.listautenti.filter(x=>x.IdUtente!=this.selected.IdUtente)
                store.commit('setlistautenti', listautenti)
                this.selected = this.listautenti[0]
                alert("Account eliminato")
            }
        },
        deletetrill() {
            var a = confirm("Sicuro di voler eliminare il trill "+this.selected.mac_address+"?")
            if(a==true){
                API.deleteTrill(this.selected.id_sensore)
                alert("Trill eliminato")
            }
        },
        addtrill() {
            API.addTrill(this.selected.IdUtente,this.selected.mac_address,this.selected.nome_sensore, this.selected.apn, this.selected.descrizione)
            alert("Trill aggiunto")
            API.getListaUtenti()
        },
        edittrill() {
            API.editTrill(this.selected.id_sensore,this.selected.mac_address,this.selected.nome_sensore, this.selected.apn, this.selected.descrizione)
            alert("Trill modificato")
        }
    },
    computed: {
        listautenti() {
            let tmp = this.$store.state.listautenti
            return tmp
        },
        datalista() {
            let tmp = []
            if (this.listautenti.length > 0) {
                for (let u of this.listautenti) {
                    tmp.push(u.nome_utente)
                }
                this.updateSelected()
            }
            return tmp
        }
    }
}
</script>

<style>
.form-control {
    margin-top: 1vh;
    margin-bottom: 1vh;
}

.fit {
    width: fit-content !important;
}
</style>
