<template>
        <Gym />
</template>
    
<style>
.container {
    height: 85vh;
    overflow-y: hidden;
    overflow-x: hidden;
}

.scroll1 {
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 10px;
}
</style>

    <script>
    import Gym from '../components/AlgoGym.vue'
    export default {
        name: 'gym',
        components: {
            Gym
        }
    }
    </script>
    