import { ref } from 'vue';

// Read the initial value from localStorage if it exists
const storedArray = localStorage.getItem('sharedArray');
let defaultItems = ["Allarme", "Wake Up Motion", "Wape Up Motion Plus"];

// Create a reactive variable using ref
const sharedArray = ref([]);

// Check if storedArray is empty or has zero items
if (!storedArray || JSON.parse(storedArray).length === 0) {
  // Add default items to sharedArray
  sharedArray.value = [...defaultItems];
  // Persist the default items in localStorage
  localStorage.setItem('sharedArray', JSON.stringify(sharedArray.value));
} else {
  // Initialize sharedArray with the stored value
  sharedArray.value = JSON.parse(storedArray);
}
// Update the sharedArray and persist it in localStorage
function updateArray(newValue) {
    sharedArray.value.push(newValue);
    localStorage.setItem('sharedArray', JSON.stringify(sharedArray.value));
  }
  
export { sharedArray, updateArray };
