<template>
<h3>Tracce disponibili:</h3>
<div class="scroll1 h50">
    <span v-for="(track, index) in trackdata" :key="index" class="track pad-v" style="justify-content: space-between;">
        <div class="square pad-or" :style="{'background-color': track.color}"></div>
        <div class="track-info" @click="changetrack(index)">
            <div class="track-name">
            <span>Traccia {{index}}</span>
            </div>
            <div class="track-dates">
            <i class="bi bi-align-start pad-or"></i> {{formatDate(track.start)}}
            </div>
            <div class="track-dates">
            <i class="bi bi-align-end pad-or"></i> {{formatDate(track.end)}}
            </div>
        </div>
        <div class="delete-btn-wrapper"><button type="button" class="btn btn-danger" @click="deletetrack(index)">X</button></div>
    </span>
</div>
</template>

<script>
/*
[{
      "start": Number,
      "end": Number,
      "totaldistance": Number,
      "path": [{}]
    }]
    import * as API from '../main.js'
    */

//import * as API from '../main.js'

export default {
    name: 'TrackList',
    emits: ["changetrack", "deletetrack"],
    methods: {
        changetrack(index) {
            this.$emit("changetrack", index);
        },
        formatDate(date) {
            date = new Date(date*1000)
            return date.toLocaleString('it-IT')
        },
        deletetrack(index) {
            this.$emit("deletetrack", index);
        }
    },
    props: {
        "trackdata": {
            type: Object
        }
    }
}
</script>

<style>
@media (max-width: 50%) {
  .track-info {
    flex-direction: column;
  }

  .delete-btn-wrapper {
    display: flex;
}

  .track {
    display: flex;
    justify-content: space-between;
}

  .track-name {
    display: flex;
    justify-content: space-between; 
  }

  .track-dates {
    margin-top: 0.5rem;
  }
}

.square {
    width: 25px;
    height: 25px;
}
.pad-or {
    margin-left: 15px;
    margin-right: 5px;
}
.pad-v {
  margin-top: 10px;
  margin-bottom: 10px;
}
.h50 {
    height: 30vh;
}
.track {
    display: flex;
    border-radius: 20px;
    border: grey 2px solid;
    padding: 10px;
}
</style>
