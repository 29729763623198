<template>
  <div class="imgtargetcontainer">
    <div
      class="text-center text-lg-start newbg d-flex justify-content-center"
      style="height: 100vh;"
    >
      <div class="row g-0 align-items-center d-flex justify-content-center">
        <div
          class="card cascading-right"
          style="
                            background: hsla(0, 0%, 100%, 0.55);
                            backdrop-filter: blur(1px);
                            border-radius: 8vh;
                            "
        >
          <div class="card-body p-5 shadow-5 text-center">
            <div class="containerimg">
              <img src="/logo-tricit-black.png" alt="" />
            </div>
            <br />
            <br />
            <form>
              <!-- Email input -->
              <div class="form-outline mb-4">
                <input
                  v-model="user"
                  name="username"
                  placeholder="Username"
                  type="text"
                  autocomplete="username"
                  class="button2"
                />
              </div>

              <!-- Password input -->
              <div class="form-outline mb-4">
                <input
                  v-model="pass"
                  name="password"
                  placeholder="Password"
                  type="password"
                  autocomplete="current-password"
                  class="button2"
                />
              </div>
              <br />
              <!-- Submit button -->
              <button type="button" class="button" @click="dologin">
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="target"></div>
  </div>
</template>

<script>
import * as API from "../main.js";

/*
function moveTarget() {
  var target = document.querySelector('.target');
  
  var container = document.querySelector('.imgtargetcontainer');
  
  var containerRect = container.getBoundingClientRect();
  var containerWidth = containerRect.width;
  var containerHeight = containerRect.height;

  console.log("Larghezza container: ", containerWidth)

  var lastLeft = parseFloat(target.style.left) || 800 / 2;
  var lastTop = parseFloat(target.style.top) || 800 / 2;
  
  var newLeft = getRandomOffset(lastLeft, 800);
  var newTop = getRandomOffset(lastTop, 800);

  target.style.left = newLeft + 'px';
  target.style.top = newTop + 'px';
}

function getRandomOffset(lastValue, containerSize) {
  var offsetRange = 20; // Adjust this value to control the range of movement
  var minOffset = lastValue - offsetRange;
  var maxOffset = lastValue + offsetRange;
  
  var randomOffset = Math.floor(Math.random() * (maxOffset - minOffset + 1) + minOffset);
  
  return Math.max(0, Math.min(randomOffset, containerSize));
}

setInterval(moveTarget, 5000); // Move target every 5 seconds
*/

export default {
  name: "Login",
  data() {
    return {
      user: "",
      pass: "",
    };
  },
  created() {
    if (this.checkLoggedIn()) {
      this.$emit("logged", true);
    } else {
      this.$emit("logged", false);
    }
  },
  mounted() {
    let _this = this;
    window.addEventListener("keyup", function(event) {
      if (event.keyCode === 13 && !_this.checkLoggedIn()) {
        _this.dologin();
      }
    });
  },
  methods: {
    checkLoggedIn() {
      let user = localStorage.getItem(API.prefix + "user");
      let token = localStorage.getItem(API.prefix + "token");
      if (user && token && token.length > 15) {
        return true;
      } else {
        return false;
      }
    },
    dologin() {
      API.dologin(this.user, this.pass);
      let _this = this;
      setTimeout(function() {
        let token = localStorage.getItem(API.prefix + "token");
        if (token) {
          _this.$emit("logged", true);
          if (_this.user && _this.pass) {
            localStorage.setItem(API.prefix + "user", _this.user);
            localStorage.setItem(API.prefix + "pass", _this.pass);
          }
          console.log("LOGGED IN T");
        } else {
          //TODO, dare errore login
          console.log("NOT LOGGED T");
        }
      }, 1000);
      console.log("OUT DOLOGIN");
    },
  },
};
</script>

<style>
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(1);
  }
}

.card{width: inherit !important}

.imgtargetcontainer {
  position: relative;
}

.target {
  position: absolute;
  top: 73%; /* Adjust the top position of the target marker */
  left: 24%; /* Adjust the left position of the target marker */
  transform: translate(-50%, -50%); /* Centers the target marker */
  width: 2vh; /* Adjust the width of the target marker */
  height: 2vh; /* Adjust the height of the target marker */
  background-color: red; /* Adjust the color of the target marker */
  border-radius: 50%; /* Makes the target marker circular */
  animation: pulse 1s infinite; /*aggiunta animazione di pulsazione*/
}

.containerimg {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: auto;
}

.containerimg img {
  max-width: 20vh;
  max-height: 20vh;
  display: block;
  margin: auto;
}

.newbg {
  background-image: url("/sfondo-trill2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

.newbg2 {
  background: linear-gradient(90deg, #80a1d4 0%, #658ec7 37%, #537cb4 94%);
  justify-content: center;
}

.cascading-right {
  margin-right: -50px;
}

@media (max-width: 991.98px) {
  .cascading-right {
    margin-right: 0;
  }
}

.button{
    width: 30%;
    border: 0px;
    color: #7D1D3F;
    background-color: #f8dbdc;
    box-shadow: 0 5px 0 #7D1D3F;
    border-radius: 5px;
    font-size: 2.5vh;
}

.button:active {
  box-shadow: none;
  transform: translate(0, 5px);
}

.button2 {
  border: 0px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  width: 70%;
  font-size: 3vh;
  border-radius: 5px;
}

.button2:active {
  box-shadow: none;
  transform: translate(0, 5px);
}
</style>
