<template>
<div class="row">
    <div class="col col-md-5">
        <h4>ALLARME</h4>
    </div>
    <div class="col col-md-2"></div>
    <div class="col col-md-5">
        <h4>STATO LAVORO</h4>
    </div>
</div>
<div class="row">
    <div class="col md-5">
        <div class="row">
            <div class="col md-3">
                <h6>ID</h6>
            </div>
            <div class="col md-3">
                <h6>DESCRIZIONE PARAMETRO</h6>
            </div>
            <div class="col md-3">
                <h6>VALORE PARAMETRO</h6>
            </div>
            <div class="col md-3">
                <h6>NUOVO VAL</h6>
            </div>
        </div>
    </div>
    <div class="col md-5">
        <div class="row">
            <div class="col md-3">
                <h6>ID</h6>
            </div>
            <div class="col md-3">
                <h6>DESCRIZIONE PARAMETRO</h6>
            </div>
            <div class="col md-3">
                <h6>VALORE PARAMETRO</h6>
            </div>
            <div class="col md-3">
                <h6>NUOVO VAL</h6>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col md-5">
        <div class="row">
            <div class="col md-1">17</div>
            <div class="col md-3">Alpha_HI</div>
            <div class="col md-3"><input type="text" id="17" v-model="this.parametri[17]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col md-2"><input type="text" id="17n" v-model="this.paramToSet[17]" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
    <div class="col md-5">
        <div class="row">
            <div class="col md-1">30</div>
            <div class="col md-3">Alpha_LO</div>
            <div class="col md-3"><input type="text" id="30" v-model="this.parametri[30]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col md-2"><input type="text" id="30n" v-model="this.paramToSet[30]" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col md-5">
        <div class="row">
            <div class="col md-1">19</div>
            <div class="col md-3">AlphaV_HI</div>
            <div class="col md-3"><input type="text" id="19" v-model="this.parametri[19]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col md-2"><input type="text" id="19n" v-model="this.paramToSet[19]" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
    <div class="col md-5">
        <div class="row">
            <div class="col md-1">32</div>
            <div class="col md-3">AlphaV_LO</div>
            <div class="col md-3"><input type="text" id="32" v-model="this.parametri[32]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col md-2"><input type="text" id="32n" v-model="this.paramToSet[32]" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col md-5">
        <div class="row">
            <div class="col md-1">21</div>
            <div class="col md-3">FreeTh_HI</div>
            <div class="col md-3"><input type="text" id="21" v-model="this.parametri[21]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col md-2"><input type="text" id="21n" v-model="this.paramToSet[21]" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
    <div class="col md-5">
        <div class="row">
            <div class="col md-1">34</div>
            <div class="col md-3">FreeTh_LO</div>
            <div class="col md-3"><input type="text" id="34" v-model="this.parametri[34]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col md-2"><input type="text" id="34n" v-model="this.paramToSet[34]" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col md-5">
        <div class="row">
            <div class="col md-1">23</div>
            <div class="col md-3">Th_HI</div>
            <div class="col md-3"><input type="text" id="23" v-model="this.parametri[23]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col md-2"><input type="text" id="23n" v-model="this.paramToSet[23]" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
    <div class="col md-5">
        <div class="row">
            <div class="col md-1">36</div>
            <div class="col md-3">Th_LO</div>
            <div class="col md-3"><input type="text" id="36" v-model="this.parametri[36]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col md-2"><input type="text" id="36n" v-model="this.paramToSet[36]" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col md-5">
        <div class="row">
            <div class="col md-1">25</div>
            <div class="col md-3">RecoverTh_HI</div>
            <div class="col md-3"><input type="text" id="25" v-model="this.parametri[25]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col md-2"><input type="text" id="25n" v-model="this.paramToSet[25]" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
    <div class="col md-5">
        <div class="row">
            <div class="col md-1">38</div>
            <div class="col md-3">RecoverTh_LO</div>
            <div class="col md-3"><input type="text" id="38" v-model="this.parametri[38]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col md-2"><input type="text" id="38n" v-model="this.paramToSet[38]" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col md-5">
        <div class="row">
            <div class="col md-1">27</div>
            <div class="col md-3">AlertTime_HI</div>
            <div class="col md-3"><input type="text" id="27" v-model="this.parametri[27]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col md-2"><input type="text" id="27n" v-model="this.paramToSet[27]" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
    <div class="col md-5">
        <div class="row">
            <div class="col md-1">40</div>
            <div class="col md-3">AlertTime_LO</div>
            <div class="col md-3"><input type="text" id="40" v-model="this.parametri[40]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col md-2"><input type="text" id="40n" v-model="this.paramToSet[40]" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col md-5">
        <div class="row">
            <div class="col md-1">28</div>
            <div class="col md-3">AlertTout_HI</div>
            <div class="col md-3"><input type="text" id="28" v-model="this.parametri[28]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col md-2"><input type="text" id="28n" v-model="this.paramToSet[28]" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
    <div class="col md-5">
        <div class="row">
            <div class="col md-1">41</div>
            <div class="col md-3">AlertTout_LO</div>
            <div class="col md-3"><input type="text" id="41" v-model="this.parametri[41]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col md-2"><input type="text" id="41n" v-model="this.paramToSet[41]" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col md-5">
        <div class="row">
            <div class="col md-1">29</div>
            <div class="col md-3">RecoveryTout_HI</div>
            <div class="col md-3"><input type="text" id="29" v-model="this.parametri[29]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col md-2"><input type="text" id="29n" v-model="this.paramToSet[29]" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
    <div class="col md-5">
        <div class="row">
            <div class="col md-1">42</div>
            <div class="col md-3">RecoveryTout_LO</div>
            <div class="col md-3"><input type="text" id="42" v-model="this.parametri[42]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col md-2"><input type="text" id="42n" v-model="this.paramToSet[42]" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col md-5">
        <button class="btn btn-secondary" @click="this.setparam(4)">Imposta ALLARME</button>&nbsp;
        <button class="btn btn-secondary" @click="this.leggi(4)">Leggi ALLARME</button>
    </div>
    <div class="col md-5">
        <button class="btn btn-secondary" @click="this.setparam(5)">Imposta LAVORO</button>&nbsp;
        <button class="btn btn-secondary" @click="this.leggi(5)">Leggi LAVORO</button>
    </div>
</div>
</template>

<script>
export default {
    name: 'AlgoParams',
    props: ["setparam", "leggi"],
    computed: {
        userdata() {
            let tmp = this.$store.state.user
            if (tmp && tmp.email) {
                return tmp
            } else {
                return {
                    email: '',
                    telefono: '',
                    Firmware: '',
                    apn: '',
                    mac_address: ''
                }
            }
        },
        paramToSet() {
            let par = this.userdata.ParamToSet
            if (par) {
                par = par.split('/')
                let tempo = {}
                for (var i = 0; i < par.length; i++) {
                    var tmp = par[i].split(";");
                    if (tmp.length > 1) {
                        var valu = tmp[1].split("-");
                        switch (tmp[0]) {
                            case "0":
                                tempo[1] = valu[0]
                                tempo[2] = valu[1]
                                tempo[3] = valu[2]
                                tempo[4] = valu[3]
                                break
                            case "1":
                                tempo[5] = valu[0]
                                tempo[7] = valu[1]
                                tempo[8] = valu[2]
                                break;
                            case "2":
                                tempo[9] = valu[0]
                                break;
                            case "3":
                                tempo[13] = valu[0]
                                tempo[14] = valu[1]
                                tempo[15] = valu[2]
                                tempo[16] = valu[3]
                                tempo[45] = valu[4]
                                break;
                            case "4":
                                tempo[17] = valu[0]
                                tempo[19] = valu[1]
                                tempo[21] = valu[2]
                                tempo[23] = valu[3]
                                tempo[25] = valu[4]
                                tempo[27] = valu[5]
                                tempo[28] = valu[6]
                                tempo[29] = valu[7]
                                break;
                            case "5":
                                tempo[39] = valu[0]
                                tempo[32] = valu[1]
                                tempo[34] = valu[2]
                                tempo[36] = valu[3]
                                tempo[38] = valu[4]
                                tempo[40] = valu[5]
                                tempo[41] = valu[6]
                                tempo[42] = valu[7]
                                break;
                            case "6":
                                tempo[43] = valu[0]
                                tempo[44] = valu[1]
                                break;
                            case "7":
                                tempo[46] = valu[0]
                                tempo[47] = valu[1]
                                tempo[48] = valu[2]
                                tempo[49] = valu[3]
                                tempo[50] = valu[4]
                                tempo[51] = valu[5]
                                tempo[52] = valu[6]
                                break;
                            default:
                                console.log("error");
                        }
                    }
                }
                return tempo
            } else {
                let tmp = {}
                for (let i = 1; i < 53; i++) {
                    tmp[i] = ""
                }
                return tmp
            }
        },
        parametri() {
            let par = this.userdata.parametri
            if (par) {
                par = par.split(';')
                let tmp = {}
                for (let i = 0; i < par.length; i++) {
                    let t = par[i].split(":")
                    tmp[t[0]] = t[1]
                    if (t.length > 2) {
                        for (let j = 2; j < t.length; j++) {
                            tmp[t[0]] += ":" + t[j]
                        }
                    }
                }
                return tmp
            } else {
                return []
            }
        }
    }
}
</script>