<template>
<nav>
    <ul class="menu">
        <li v-if="permessoPresente('9')"><a href="/admin/">Admin</a></li>
        <li><a href="/home/">Home</a></li>
        <li v-if="permessoPresente('3')"><a href="/dashboard/">Dashboard</a></li>
        <li v-if="permessoPresente('4')"><a href="/params/">Parametri</a></li>
        <li v-if="permessoPresente('10')"><a href="/racing/">Racing</a></li>
        <li><a href="/algo/">Algoritmi</a></li>
        <li v-if="permessoPresente('9')"><a href="/gym/">PalestraAlg</a></li>
        <li><a href="/account/">Account</a></li>
        <li v-if="permessoPresente('7')"><a href="/firmware/">Firmware</a></li>
        <li><a href="#" @click="dologout">Logout</a></li>
    </ul>
</nav>
</template>

<script>
import * as API from '../main.js'

export default {
    name: 'Navbar',
    methods: {
        dologout(){
            API.dologout()
            this.$parent.$emit("logged", false);
            window.location.href = "/home/"
        },
        permessoPresente(perm){
            let p = this.permessi
            if(p.indexOf(perm)==-1){
                return false
            }else{
                return true
            }
        }
    },
    props: ["permessi"]
}
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Varela+Round");

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
    letter-spacing: 1.1px;
}

.menu {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    background: #fff;
    padding: 2vh;
    position: fixed;
    margin-bottom: 0;
    width: 100vmax;
    bottom: 0;
    left: 0;
    z-index: 10000;
}

.menu li {
    width: 125px;
    height: 50px;
    transition: background-position-x 0.9s linear;
    text-align: center;
}

.menu li a {
    font-size: 22px;
    color: #777;
    text-decoration: none;
    transition: all 0.45s;
}

.menu li:hover {
    background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEi%0D%0AIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhs%0D%0AaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB3aWR0%0D%0AaD0iMzkwcHgiIGhlaWdodD0iNTBweCIgdmlld0JveD0iMCAwIDM5MCA1MCIgZW5hYmxlLWJhY2tn%0D%0Acm91bmQ9Im5ldyAwIDAgMzkwIDUwIiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBhdGggZmlsbD0i%0D%0Abm9uZSIgc3Ryb2tlPSIjZDk0ZjVjIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLW1pdGVybGlt%0D%0AaXQ9IjEwIiBkPSJNMCw0Ny41ODVjMCwwLDk3LjUsMCwxMzAsMAoJYzEzLjc1LDAsMjguNzQtMzgu%0D%0ANzc4LDQ2LjE2OC0xOS40MTZDMTkyLjY2OSw0Ni41LDI0My42MDMsNDcuNTg1LDI2MCw0Ny41ODVj%0D%0AMzEuODIxLDAsMTMwLDAsMTMwLDAiLz4KPC9zdmc+Cg==");
    -webkit-animation: line 1s;
    animation: line 1s;
}

.menu li:hover a {
    color: #d94f5c;
}

.menu li {
    margin-right: 30px;
}

@-webkit-keyframes line {
    0% {
        background-position-x: 390px;
    }
}

@keyframes line {
    0% {
        background-position-x: 390px;
    }
}
</style>
