<template>
    <div class="tvh-dateTimeWrapper">
        <div class="tvh-containerWrapper">
            <div class="tvh-dateContainer">
                <DatePicker
                    ref="datePickerRef"
                    :startDate="innerStartDate"
                    :endDate="innerEndDate"
                    @onChange="__onChange"
                    :singleDate="singleDate"
                />
            </div>
            <div class="tvh-timeContainer">
                <div class="tvh-startTime tvh-timeRow">
                    <span class="tvh-subTitle">From</span>
                    <div>
                        <span class="tvh-bigNumber">{{ innerStartDate.getDate() }}</span>
                        {{ getShortMonth(innerStartDate.getMonth()) }}
                        {{ innerStartDate.getFullYear() }}
                    </div>
                    <time-picker
                        :format="timeFormat"
                        v-bind:value="defaultStartTime"
                        @change="_onChangeTimeStart"
                        size="lg"
                    />
                </div>
                <div class="tvh-endTime tvh-timeRow" v-if="!singleDate">
                    <span class="tvh-subTitle">To</span>
                    <div>
                        <span class="tvh-bigNumber">{{ innerEndDate.getDate() }}</span>
                        {{ getShortMonth(innerEndDate.getMonth()) }}
                        {{ innerEndDate.getFullYear() }}
                    </div>
                    <time-picker
                        :format="timeFormat"
                        v-bind:value="defaultEndTime"
                        @change="_onChangeTimeEnd"
                        size="lg"
                    />
                </div>
            </div>
        </div>
        <div class="tvh-buttonWrap">
            <a class="tvh-confirm" @click.stop.prevent="__onSubmit">confirm</a>
            <a class="tvh-cancel" @click.stop.prevent="__onCancel">cancel</a>
        </div>
    </div>
</template>

<script>
import TimePicker from "./TimePicker";
import DatePicker from "./DatePicker";
import * as utils from "../lib/date";
import { getTimeObjectFromDate } from "../lib/time";

const DEFAULT_START_TIME = {
    hh: "00",
    mm: "00",
    A: "AM"
};

const DEFAULT_END_TIME = {
    hh: "12",
    mm: "59",
    A: "PM"
};

export default {
    name: "DateTimePickerModal",
    components: { DatePicker, TimePicker },
    methods: {
        callEvent: function(eventName, data) {
            return this.$emit(eventName, data);
            /*if (this[eventName]) {
                return this[eventName](data);
            }*/
        },
        __onChange: function(data) {
            return this.singleDate
                ? this._onChangeSingleDate(data)
                : this._onChangeMultiDate(data);
        },
        __onSubmit: function() {
            return this.singleDate
                ? this._submitSingleHandler()
                : this._submitMultiHandler();
        },
        getShortMonth: function(monthIndex) {
            return utils.monthShortConfig[monthIndex];
        },
        __onCancel: function() {
            return this.callEvent("cancelHandler");
        },
        _submitSingleHandler: function() {
            // eslint-disable-next-line no-unused-vars
            const { innerStartTime: startTime, innerEndTime: endTime } = this;

            const { innerStartDate: startDate } = this.$refs.datePickerRef;
            const startDateString = utils.format(startDate, "yy-mm-dd");
            const startTimeString = `${startTime.HH || "00"}:${startTime.mm || "00"}`;
            const startDateObject = new Date(`${startDateString}T${startTimeString}`);

            const returnData = {
                startDate: startDateObject,
                startTime
            };

            return this.callEvent("submitHandler", returnData);
        },
        _submitMultiHandler: function() {
            const { innerStartTime: startTime, innerEndTime: endTime } = this;

            const {
                innerStartDate: startDate,
                innerEndDate: endDate
            } = this.$refs.datePickerRef;

            const startDateString = utils.format(startDate, "yy-mm-dd");
            const endDateString = utils.format(endDate, "yy-mm-dd");
            const startTimeString = `${startTime.HH}:${startTime.mm}`;
            const endTimeString = `${endTime.HH}:${endTime.mm}`;
            const startDateObject = new Date(`${startDateString}T${startTimeString}`);
            const endDateObject = new Date(`${endDateString}T${endTimeString}`);

            const returnData = {
                startDate: startDateObject,
                endDate: endDateObject,
                startTime,
                endTime
            };

            return this.callEvent("submitHandler", returnData);
        },
        _onChangeMultiDate: function(data) {
            const { startDate, endDate } = data;
            this.innerStartDate = startDate;
            this.innerEndDate = endDate;
        },
        _onChangeSingleDate: function(data) {
            const { startDate } = data;
            this.innerStartDate = startDate;
        },
        _onChangeTimeStart: function(val) {
            return this._onChangeTime("innerStartTime", val);
        },
        _onChangeTimeEnd: function(val) {
            return this._onChangeTime("innerEndTime", val);
        },
        _onChangeTime: function(field, val) {
            this[field] = val.data;
        }
    },
    props: {
        submitHandler: Function,
        startDate: Date,
        endDate: Date,
        timeFormat: {
            type: String,
            default: "hh:mm:A"
        },
        singleDate: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        const today = new Date();
        const {
            startDate = today,
            timeFormat = "hh:mm:A",
            endDate = utils.getDayAfter(today, 2)
        } = this;
        const startTime = getTimeObjectFromDate(startDate, timeFormat);
        const endTime = getTimeObjectFromDate(endDate, timeFormat);

        return {
            defaultStartTime: startTime || DEFAULT_START_TIME,
            defaultEndTime: endTime || DEFAULT_END_TIME,
            innerStartDate: startDate,
            innerEndDate: endDate,
            innerStartTime: startTime,
            innerEndTime: endTime
        };
    }
};
</script>
