<template>
<div class="row">
    <div class="col md-10">
        <h4>MEMS, SERVER, DEVICE, WAKEUP</h4>
    </div>
</div>
<div class="row" style="padding:5px">
    <div class="col col-md-8">
        <div class="row">
            <div class="col col-md-2">
                <h6>GRUPPO</h6>
            </div>
            <div class="col col-md-1">
                <h6>ID</h6>
            </div>
            <div class="col col-md-3">
                <h6>DESCRIZIONE PARAMETRO</h6>
            </div>
            <div class="col col-md-3">
                <h6>VALORE PARAMETRO</h6>
            </div>
            <div class="col col-md-3">
                <h6>NUOVO VAL</h6>
            </div>
        </div>
    </div>
    <div class="col col-md-4">
        <div class="row">
            <div class="col col-md-6">
                <button class="btn btn-secondary" @click="this.leggifw()">Leggi Firmware</button>
            </div>
            <div class="col col-md-6">
                <button class="btn btn-secondary" @click="this.leggibatt()">Leggi batteria</button>
            </div>
        </div>
        <div class="row" style="margin-top:1vh">
            <div class="col col-md-6 offset-md-3">
                <button class="btn btn-secondary" @click="this.leggitemp()">Leggi temperatura</button>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col col-md-8">
        <div class="row">
            <div class="col col-md-2">MEMS</div>
            <div class="col col-md-1">1</div>
            <div class="col col-md-3">Fondo scala accelerometri</div>
            <div class="col col-md-3"><input type="text" v-model="this.parametri[1]" id="1" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col col-md-3"><input type="text" v-model="this.paramToSet[1]" id="1n" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
    <div class="col col-md-4">
        <button class="btn btn-secondary" @click="this.setparam(0)">Imposta MEMS</button>&nbsp;
        <button class="btn btn-secondary" @click="this.leggi(0)">Leggi MEMS</button>
    </div>
</div>
<div class="row">
    <div class="col col-md-8">
        <div class="row">
            <div class="col col-md-2"></div>
            <div class="col col-md-1">2</div>
            <div class="col col-md-3">Divisore ODR accelerometri</div>
            <div class="col col-md-3"><input type="text" v-model="this.parametri[2]" id="2" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col col-md-3"><input type="text" v-model="this.paramToSet[2]" id="2n" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col col-md-8">
        <div class="row">
            <div class="col col-md-2"></div>
            <div class="col col-md-1">3</div>
            <div class="col col-md-3">Fondo scala giroscopi</div>
            <div class="col col-md-3"><input type="text" id="3" v-model="this.parametri[3]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col col-md-3"><input type="text" v-model="this.paramToSet[3]" id="3n" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col col-md-8">
        <div class="row">
            <div class="col col-md-2"></div>
            <div class="col col-md-1">4</div>
            <div class="col col-md-3">Divisore ODR giroscopi</div>
            <div class="col col-md-3"><input type="text" id="4" v-model="this.parametri[4]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col col-md-3"><input type="text" id="4n" v-model="this.paramToSet[4]" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col col-md-8">
        <div class="row">
            <div class="col col-md-2">WAKEUP MOTION</div>
            <div class="col col-md-1">43</div>
            <div class="col col-md-3">WakeUp Motion</div>
            <div class="col col-md-3"><input type="text" id="43" v-model="this.parametri[43]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col col-md-3"><input type="text" id="43n" v-model="this.paramToSet[43]" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
    <div class="col col-md-4">
        <button class="btn btn-secondary" style="margin: 1vh" @click="this.setparam(6)">Set WAKEUP-MOT</button>&nbsp;
        <button class="btn btn-secondary" @click="this.leggi(6)">Leggi WAKEUP-MOT</button>
    </div>
</div>
<div class="row">
    <div class="col col-md-8">
        <div class="row">
            <div class="col col-md-2"></div>
            <div class="col col-md-1">44</div>
            <div class="col col-md-3">Soglia WakeUp Motion</div>
            <div class="col col-md-3"><input type="text" id="44" v-model="this.parametri[44]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col col-md-3"><input type="text" id="44n" v-model="this.paramToSet[44]" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col col-md-8">
        <div class="row">
            <div class="col col-md-2">SERVER</div>
            <div class="col col-md-1">5</div>
            <div class="col col-md-3">Indirizzo IP del server</div>
            <div class="col col-md-3"><input type="text" id="5" v-model="this.parametri[5]" class="form-control" style="max-width:300px" />&nbsp;</div>
            <div class="col col-md-3"><input type="text" id="5n" v-model="this.paramToSet[5]" disabled class="form-control" style="max-width:300px" />&nbsp;</div>
        </div>
    </div>
    <div class="col md-2">
        <button class="btn btn-secondary" @click="this.setparam(1)">Imposta SERVER</button>&nbsp;
        <button class="btn btn-secondary" @click="this.leggi(1)">Leggi SERVER</button>
    </div>
</div>
<div class="row">
    <div class="col col-md-8">
        <div class="row">
            <div class="col col-md-2"></div>
            <div class="col col-md-1">7</div>
            <div class="col col-md-3">Numero di porta del server</div>
            <div class="col col-md-3"><input type="text" id="7" v-model="this.parametri[7]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col col-md-3"><input type="text" id="7n" v-model="this.paramToSet[7]" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col col-md-8">
        <div class="row">
            <div class="col col-md-2"></div>
            <div class="col col-md-1">8</div>
            <div class="col col-md-3">Timeout di connessione del server</div>
            <div class="col col-md-3"><input type="text" id="8" v-model="this.parametri[8]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col col-md-3"><input type="text" id="8n" v-model="this.paramToSet[8]" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col col-md-8">
        <div class="row">
            <div class="col col-md-2">DISPOSITIVO</div>
            <div class="col col-md-1">9</div>
            <div class="col col-md-3">ID del dispositivo</div>
            <div class="col col-md-3"><input type="text" id="9" v-model="this.parametri[9]" class="form-control" style="max-width:300px" />&nbsp;</div>
            <div class="col col-md-3"><input type="text" id="9n" v-model="this.paramToSet[9]" disabled class="form-control" style="max-width:300px" />&nbsp;</div>
        </div>
    </div>
    <div class="col md-2">
        <button class="btn btn-secondary" @click="this.setparam(2)">Imposta DEVICE</button>&nbsp;
        <button class="btn btn-secondary" @click="this.leggi(2)">Leggi DEVICE</button>
    </div>
</div>
<div class="row">
    <div class="col col-md-8">
        <div class="row">
            <div class="col col-md-2">WAKEUP</div>
            <div class="col col-md-1">13</div>
            <div class="col col-md-3">Periodo di wakeup</div>
            <div class="col col-md-3"><input type="text" id="13" v-model="this.parametri[13]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col col-md-3"><input type="text" id="13n" v-model="this.paramToSet[13]" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
    <div class="col md-2">
        <button class="btn btn-secondary" @click="this.setparam(3)">Imposta WAKEUP</button>&nbsp;
        <button class="btn btn-secondary" @click="this.leggi(3)">Leggi WAKEUP</button>
    </div>
</div>
<div class="row">
    <div class="col col-md-8">
        <div class="row">
            <div class="col col-md-2"></div>
            <div class="col col-md-1">14</div>
            <div class="col col-md-3">Timeout di wakeup</div>
            <div class="col col-md-3"><input type="text" id="14" v-model="this.parametri[14]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col col-md-3"><input type="text" id="14n" v-model="this.paramToSet[14]" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col col-md-8">
        <div class="row">
            <div class="col col-md-2"></div>
            <div class="col col-md-1">15</div>
            <div class="col col-md-3">Modalita di avvio della rete</div>
            <div class="col col-md-3"><input type="text" id="15" v-model="this.parametri[15]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col col-md-3"><input type="text" id="15n" v-model="this.paramToSet[15]" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col col-md-8">
        <div class="row">
            <div class="col col-md-2"></div>
            <div class="col col-md-1">16</div>
            <div class="col col-md-3">Modalita a basso consumo</div>
            <div class="col col-md-3"><input type="text" id="16" v-model="this.parametri[16]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col col-md-3"><input type="text" id="16n" v-model="this.paramToSet[16]" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col col-md-8">
        <div class="row">
            <div class="col col-md-2"></div>
            <div class="col col-md-1">45</div>
            <div class="col col-md-3">Numero di eventi di fix del GPS</div>
            <div class="col col-md-3"><input type="text" id="45" v-model="this.parametri[45]" class="form-control" style="max-width:70px" />&nbsp;</div>
            <div class="col col-md-3"><input type="text" id="45n" v-model="this.paramToSet[45]" disabled class="form-control" style="max-width:70px" />&nbsp;</div>
        </div>
    </div>
</div>
<div class="row"></div>
<AlgoParams :leggi="leggi" :setparam="setparam" />

<div style="height: 20vh"></div>
</template>

<script>
import * as API from '../main.js'
import AlgoParams from './AlgoParams.vue'

export default {
    name: 'Param',
    created() {
        let _this = this
        setTimeout(function () {
            if (_this.userdata.mac_address.length == 0) {
                API.getUser()
            }
        }, 2000)
    },
    components: {
        AlgoParams
    },
    computed: {
        userdata() {
            let tmp = this.$store.state.user
            if (tmp && tmp.email) {
                return tmp
            } else {
                return {
                    email: '',
                    telefono: '',
                    Firmware: '',
                    apn: '',
                    mac_address: ''
                }
            }
        },
        paramToSet() {
            let par = this.userdata.ParamToSet
            if (par) {
                par = par.split('/')
                let tempo = {}
                for (var i = 0; i < par.length; i++) {
                    var tmp = par[i].split(";");
                    if (tmp.length > 1) {
                        var valu = tmp[1].split("-");
                        switch (tmp[0]) {
                            case "0":
                                tempo[1] = valu[0]
                                tempo[2] = valu[1]
                                tempo[3] = valu[2]
                                tempo[4] = valu[3]
                                break
                            case "1":
                                tempo[5] = valu[0]
                                tempo[7] = valu[1]
                                tempo[8] = valu[2]
                                break;
                            case "2":
                                tempo[9] = valu[0]
                                break;
                            case "3":
                                tempo[13] = valu[0]
                                tempo[14] = valu[1]
                                tempo[15] = valu[2]
                                tempo[16] = valu[3]
                                tempo[45] = valu[4]
                                break;
                            case "4":
                                tempo[17] = valu[0]
                                tempo[19] = valu[1]
                                tempo[21] = valu[2]
                                tempo[23] = valu[3]
                                tempo[25] = valu[4]
                                tempo[27] = valu[5]
                                tempo[28] = valu[6]
                                tempo[29] = valu[7]
                                break;
                            case "5":
                                tempo[39] = valu[0]
                                tempo[32] = valu[1]
                                tempo[34] = valu[2]
                                tempo[36] = valu[3]
                                tempo[38] = valu[4]
                                tempo[40] = valu[5]
                                tempo[41] = valu[6]
                                tempo[42] = valu[7]
                                break;
                            case "6":
                                tempo[43] = valu[0]
                                tempo[44] = valu[1]
                                break;
                            case "7":
                                tempo[46] = valu[0]
                                tempo[47] = valu[1]
                                tempo[48] = valu[2]
                                tempo[49] = valu[3]
                                tempo[50] = valu[4]
                                tempo[51] = valu[5]
                                tempo[52] = valu[6]
                                break;
                            default:
                                console.log("error");
                        }
                    }
                }
                return tempo
            } else {
                let tmp = {}
                for (let i = 1; i < 53; i++) {
                    tmp[i] = ""
                }
                return tmp
            }
        },
        parametri() {
            let par = this.userdata.parametri
            if (par) {
                par = par.split(';')
                let tmp = {}
                for (let i = 0; i < par.length; i++) {
                    let t = par[i].split(":")
                    tmp[t[0]] = t[1]
                    if (t.length > 2) {
                        for (let j = 2; j < t.length; j++) {
                            tmp[t[0]] += ":" + t[j]
                        }
                    }
                }
                return tmp
            } else {
                return []
            }
        }
    },
    methods: {
        setparam(p) {
            var msg = ""; //"set;"+a+";"+msg
            if (p == 0) {
                msg = p + ";" + this.parametri[1] + '-' + this.parametri[2] + '-' + this.parametri[3] + '-' + this.parametri[4];
            } else if (p == 1) {
                msg = p + ";" + this.parametri[5] + '-' + this.parametri[7] + '-' + this.parametri[8]
            } else if (p == 2) {
                msg = p + ";" + this.parametri[9]
            } else if (p == 3) {
                msg = p + ";" + this.parametri[13] + '-' + this.parametri[14] + '-' + this.parametri[15] + '-' + this.parametri[16] + '-' + this.parametri[45]
            } else if (p == 4) {
                msg = p + ";" + this.parametri[17] + '-' + this.parametri[19] + '-' + this.parametri[21] + '-' + this.parametri[23] + '-' + this.parametri[25] + '-' + this.parametri[27] + '-' + this.parametri[28] + '-' + this.parametri[29]
            } else if (p == 5) {
                msg = p + ";" + this.parametri[30] + '-' + this.parametri[32] + '-' + this.parametri[34] + '-' + this.parametri[36] + '-' + this.parametri[38] + '-' + this.parametri[40] + '-' + this.parametri[41] + '-' + this.parametri[42]
            } else if (p == 6) {
                msg = p + ";" + this.parametri[43] + '-' + this.parametri[44];
            } else if (p == 7) {
                msg = p + ";" + this.parametri[46] + '-' + this.parametri[47] + '-' + this.parametri[48] + '-' + this.parametri[49] + '-' + this.parametri[50] + '-' + this.parametri[51] + '-' + this.parametri[52]
            }
            let userdata = this.userdata
            if(userdata.ParamToSet&& userdata.ParamToSet.length>0){
                userdata.ParamToSet+="/"+msg
                API.updateUser(userdata)
            }
            API.setParams(msg)
        },
        leggi(num) {
            API.readParams(num)
        },
        leggibatt(){
            API.readParams("","batt")
        },
        leggitemp(){
            API.readParams("","temp")
        },
        leggifw(){
            API.readParams("","fw")
        }
    }
}
</script>
