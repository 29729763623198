<template>
<div class="main-container">
    <div class="card-container row">
        <!--
        <div v-if="this.subcategories[this.category]" class="col" style="display: contents;" >
            <div v-for="(cat, key) in this.subcategories[this.category]" v-bind:key="key" :class="this.category + ' card'">
                    <h2 class="card__title">{{cat}}</h2>
                    <--<p class="card__apply" @click="godetail"><a class="link">Carica adesso--
                    <p class="card__apply"><a class="link">Carica adesso
                            <font-awesome-icon icon="arrow-right" /></a></p>
            </div>
            <div class="card back">
                <h2 class="card__title">Annulla</h2>
                <p class="card__apply"><a class="link" @click="goback()">Torna indietro..
                        <font-awesome-icon icon="arrow-right" /></a></p>
            </div>
        </div>-->
        <!---->
        <div v-if="this.category === 'sport'" class="col" style="display: contents;">
            <div v-for="item in items" :key="item.id" :class="this.category + ' card'">
                    <h3 class="card__title">{{item}}</h3>
                    <p class="card__apply"><a class="link">Carica adesso
                            <font-awesome-icon icon="arrow-right" /></a></p>
                    <!-- Other card content -->
            </div>
            <div class="card back" id="yep">
                <h2 class="card__title">Annulla</h2>
                <p class="card__apply"><a class="link" @click="goback()">Torna indietro..
                <font-awesome-icon icon="arrow-right" /></a></p>
            </div>
        </div>
        <div v-else-if="this.category === 'anziani' || this.category === 'salute' || this.category === 'lavoro' || this.category === 'trackcat' || this.category === 'sicurezza'" class="col" style="display: contents;">
            <div v-for="(cat, key) in this.subcategories[this.category]" v-bind:key="key" :class="this.category + ' card'">
                <h2 class="card__title">{{cat}}</h2>
                <!--<p class="card__apply" @click="godetail"><a class="link">Carica adesso-->
                <p class="card__apply"><a class="link">Carica adesso
                        <font-awesome-icon icon="arrow-right" /></a></p>
            </div>
            <div class="card back" id="nope">
                <h2 class="card__title">Annulla</h2>
                <p class="card__apply"><a class="link" @click="goback()">Torna indietro..
                        <font-awesome-icon icon="arrow-right" /></a></p>
            </div>
        </div>
    </div>
</div>
<Gym class="pippo" @addtosport="aggiungisport"></Gym>
</template>

<script>
import Gym from '../components/AlgoGym.vue'

export default {
    name: 'AlgoDetail',
    components: {
        Gym
    },
    props: ["category"],
    emits: ["changeCategory"],
    data() {
        return {
            subcategories: {
                /*"sport": ["Escursionismo", "Scalata", "Kitesurf", "Windsurf", "Snowboard", "Sci", "Motocross", "Mountainbike"],*/
                "sport": ["Allarme", "Wake Up Motion", "Wape Up Motion Plus"],
                "anziani": ["Rilevamento cadute", "Chiamata di emergenza"],
                "salute": ["Allerta pressione", "Allerta temperatura corporea", "Allerta battito cardiaco"],
                "lavoro": ["Uomo a terra", "Avviso temperatura"],
                "trackcat": ["Auto", "Moto", "Bicicletta", "Animali"],
                "sicurezza": ["Emergenza FDO"]
            },
            items:[]
        }
    },
    mounted() {
        // Retrieve the array from local storage
        const storedItems = localStorage.getItem('sharedArray');

        if (storedItems) {
        // Parse the retrieved string into an array
        this.items = JSON.parse(storedItems);
        }
    },
    methods: {
        goback() {
            this.$emit('changeCategory','')
        },
        godetail() {
            this.$emit('changeCategory','algoparams')
        },
        aggiungisport(newItem) {
            console.log("sono in algodetail: "+ newItem)
            //this.subcategories.sport.push(newItem)
        }
    }
}
</script>

<style>
.back {
    background: radial-gradient(#4040ff, #120a8f);
}
.pippo{
    display: none;
}
</style>
