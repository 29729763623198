<template>
<div class="container" style="overflow-y: scroll">
    <div class="row">
        <div class="col-sm-12">&nbsp;</div>
    </div>
    <div class="row">
        <div class="col-sm-3">
            <h4>Firmware disponibili</h4>
        </div>
        <div class="col-sm-3">
            <div class="input-group">
                <div class="custom-file"><input id="ff" @change="saveFile($event)" type="file" name="ff" class="custom-file-input" />
                    <label class="custom-file-label" for="inputGroupFile01">Seleziona file</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">&nbsp;</div>
    </div>
    <div class="row">
        <div class="col-sm-7">
            <table id="fw" class="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>Firmware:</th>
                        <th>Data caricamento:</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="file in fw" :key="file.name">
                        <td>{{file.name}}</td>
                        <td>{{file.date}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-3">Seleziona FW:<br>
            <select id="selfw" class="form-control" v-model="selfw">
                <option v-for="file in fw" :key="file.name">{{file.name}}</option>
            </select>
        </div>
    </div>
    <div class="row">
        &nbsp;
    </div>
    <div class="row pad-v">
        <div class="col-sm-4">
            <input id="trill" class="form-control" v-model="this.userdata.mac_address" disabled>
        </div>
        <div class="col-sm-2"><button @click="carica" class="btn btn-success">Carica FW</button><br></div>
    </div>
    <div class="row">
        <div class="col col-md-12" style="margin-top: 1vh">
            <div class="table-responsive-md">
                <VueDatatable :options="this.tableOptions" :key="refresh" />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import VueDatatable from "../components/VueDatatable";
import * as API from '../main.js'

export default {
    name: 'Firmware',
    components: {
        VueDatatable
    },
    data() {
        return {
            selfw: "",
            refresh: 0
        }
    },
    created() {
        if (this.userdata.mac_address.length == 0) {
            API.getUser()
            API.getFW()
        }
    },
    methods: {
        carica() {
            if (this.selfw.length > 1) {
                let _this = this
                API.setTrillAgg(this.userdata.id_sensore, this.selfw).then(() => {
                    setTimeout(function () {
                        _this.refresh++;
                    }, 1000);
                })
            } else {
                alert("Selezionare firmware!")
            }
        },
        saveFile(event) {
            let formData = new FormData()
            let file = event.target.files[0]
            formData.append("file", file)

            fetch(API.apiurl + '/upload', {
                method: "POST",
                body: formData
            }).then(function (response) {
                return response.json();
            }).then(function (json) {
                console.log(json);
            });
        }
    },
    computed: {
        tableOptions() {
            return {
                tableName: 'users',
                columns: [{
                        title: 'Id',
                        key: 'id_sensore',
                        type: 'clickable',
                        uniqueField: 'id_sensore',
                        textAlign: 'left',
                        sortable: true
                    },
                    {
                        title: 'Id Trill',
                        key: 'mac_address',
                        type: 'text',
                        textAlign: 'left',
                        sortable: true
                    },
                    {
                        title: 'FW Da Caricare',
                        key: 'DaAggiornare',
                        type: 'text',
                        textAlign: 'left',
                        sortable: true
                    },
                    {
                        title: 'Azioni',
                        key: 'azioni',
                        type: 'html',
                        textAlign: 'left',
                        key2: "id_sensore"
                    },
                ],
                source: API.apiurl + "/getTrilldaAgg?token=" + localStorage.getItem(API.prefix + "token"),
                search: false,
            }
        },
        userdata() {
            let tmp = this.$store.state.user
            if (tmp && tmp.email) {
                return tmp
            } else {
                return {
                    email: '',
                    telefono: '',
                    Firmware: '',
                    apn: '',
                    mac_address: ''
                }
            }
        },
        fw() {
            let tmp = this.$store.state.fw
            return tmp
        }
    }
}
</script>

<style>
.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin: 0;
    opacity: 0;
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}

.input-group>.custom-file {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.input-group>.custom-file,
.input-group>.custom-select,
.input-group>.form-control {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

label {
    display: inline-block;
    margin-bottom: .5rem;
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.25rem + 2px) - 1px * 2);
    padding: .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 .25rem .25rem 0;
}
</style>
